import React, { useCallback } from 'react';

import styles from './TopBar.module.css';
import { useUser } from '@hubblai/hubbl-ui/store/auth/hooks.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { Logo, Button, Icon, DropdownMenu, DropdownMenuItem } from '@hubblai/hubbl-ui/components/index.js';
import { useAppDispatch, actions } from '@hubblai/hubbl-ui/store/index.js';
import { confirmDialog } from 'primereact/confirmdialog';


const TopBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onClickConfirmLogout = () => {
    actions.resetAll(dispatch);
    navigate('/');
  }

  const onClickLogout = () => {
    confirmDialog({
      message:
        <>
          <div>Are you sure you want to log out?</div>
        </>
      ,
      header: 'Log out',
      icon: 'pi pi-exclamation-triangle',
      accept: onClickConfirmLogout,
    });
  }

  const menuItems: DropdownMenuItem[] = [
    {
      icon: 'profile',
      label: "Profile",
      onClick: () => { navigate('/users/me') }
    },
    {
      icon: 'users',
      label: "Invitations",
      onClick: () => { navigate('/users/me/invitations') }
    },
    {
      label: '-'
    },
    {
      icon: 'leave',
      label: 'Logout',
      onClick: onClickLogout,
    },
  ];

  const returnToChats = useCallback(() => {
    return pathname.startsWith('/users');
  }, [pathname]);

  const onClickLogo = () => {
    if (returnToChats()) {
      navigate('/');
    } else {
      navigate('/');
    }
  }

  const shouldReturnToChats = returnToChats();

  return (
    <div className={styles.Root}>
      <Button className={styles.AvatarButton} onClick={onClickLogo}>
        {!shouldReturnToChats &&
          <div className={styles.Brand}>
            <div className={styles.Logo}><Logo width={20} /></div>
            <span>HUBBL</span>
          </div>}
        {shouldReturnToChats &&
          <div className={styles.Brand}>
            <Icon name='back' className={styles.AvatarButtonIcon} />
            <span>BACK</span>
          </div>
        }
      </Button>
      <div className="flex flex-row items-center">
        <DropdownMenu items={menuItems} buttonClassName={styles.AvatarButton} dropdownClassName='w-60'>
          <img src={user?.getAvatar()} alt={user?.getDisplayName()} />
        </DropdownMenu>
      </div>
    </div>
  );
}

export default TopBar;
