export const FETCH_CHATS_REQUEST_START = "FETCH_CHATS_REQUEST_START";
export const FETCH_CHATS_REQUEST_SUCCESS = "FETCH_CHATS_REQUEST_SUCCESS";
export const FETCH_CHATS_REQUEST_FAILURE = "FETCH_CHATS_REQUEST_FAILURE";

export const FETCH_CHAT_REQUEST_START = "FETCH_CHAT_REQUEST_START";
export const FETCH_CHAT_REQUEST_SUCCESS = "FETCH_CHAT_REQUEST_SUCCESS";
export const FETCH_CHAT_REQUEST_FAILURE = "FETCH_CHAT_REQUEST_FAILURE";

export const CREATE_CHAT_REQUEST_START = "CREATE_CHAT_REQUEST_START";
export const CREATE_CHAT_REQUEST_SUCCESS = "CREATE_CHAT_REQUEST_SUCCESS";
export const CREATE_CHAT_REQUEST_FAILURE = "CREATE_CHAT_REQUEST_FAILURE";

export const LEAVE_CHAT_REQUEST_START = "LEAVE_CHAT_REQUEST_START";
export const LEAVE_CHAT_REQUEST_SUCCESS = "LEAVE_CHAT_REQUEST_SUCCESS";
export const LEAVE_CHAT_REQUEST_FAILURE = "LEAVE_CHAT_REQUEST_FAILURE";

export const MARK_AS_READ_START = 'MARK_AS_READ_START';
export const MARK_AS_READ_SUCCESS = 'MARK_AS_READ_SUCCESS';
export const MARK_AS_READ_FAILURE = 'MARK_AS_READ_FAILURE';

export const EDIT_CHAT_START = 'EDIT_CHAT_START';
export const EDIT_CHAT_SUCCESS = 'EDIT_CHAT_START';
export const EDIT_CHAT_FAILURE = 'EDIT_CHAT_FAILURE';

export const INVITE_AGENT_REQUEST_START = 'INVITE_AGENT_REQUEST_START';
export const INVITE_AGENT_REQUEST_SUCCESS = 'INVITE_AGENT_REQUEST_SUCCESS';
export const INVITE_AGENT_REQUEST_FAILURE = 'INVITE_AGENT_REQUEST_FAILURE';

export const UNINVITE_AGENT_REQUEST = {
  START: "UNINVITE_AGENT_REQUEST.START",
  SUCCESS: "UNINVITE_AGENT_REQUEST.SUCCESS",
  FAILURE: "UNINVITE_AGENT_REQUEST.FAILURE",
}

export const ON_CHAT_USER = {
  ADDED: "ON_CHAT_USER.ADDED",
  REMOVED: "ON_CHAT_USER.REMOVED",
};
export const ON_CHAT_AGENT = {
  ADDED: "ON_CHAT_AGENT.ADDED",
  REMOVED: "ON_CHAT_AGENT.REMOVED",
};

// Shared with Messages
export const ON_MESSAGE_RECEIVED = 'ON_MESSAGE_RECEIVED';
export const ON_MESSAGE_UPDATED = 'ON_MESSAGE_UPDATED';
export const ON_MESSAGE_CONTENT_UPDATED = 'ON_MESSAGE_CONTENT_UPDATED';
export const ON_LOCAL_MESSAGE_RECEIVED = 'ON_LOCAL_MESSAGE_RECEIVED';
