import React, { useCallback, useEffect, useState } from 'react';
import { Input, Button, Spinner, Tag } from '@hubblai/hubbl-ui/components/index.js';
import styles from './UserInvitations.module.css';
import API from '@hubblai/hubbl-ui/lib/api.js';
import config from '~/config';
import { addToast } from '@hubblai/hubbl-ui/store/notifications/actions.js';
import { useAppDispatch } from '@hubblai/hubbl-ui/store/index.js';
import { toDisplayDateTime } from '@hubblai/hubbl-core/lib/clock.js';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { INVITATION_STATUS } from '@hubblai/hubbl-core/models/Invitation.js';
import { Dialog } from 'primereact/dialog';
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  XIcon
} from 'react-share';


// type Props = {

// }

type Invitation = {
  token: string,
  url: string,
  status: number,
  created_at: number,
}

const fromDTO = (data: any): Invitation => {
  return {
    token: data.token,
    url: data.url,
    status: data.status,
    created_at: data.created_at,
  }
}

const UserInvitations: React.FC = () => {
  const dispatch = useAppDispatch();
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [shareInvitation, setShareInvitation] = useState<Invitation | undefined>();

  const canGenerateInvitation = invitations.length < config.INVITATION_LIMIT_PER_USER;

  const fetchInvitations =  useCallback(async () => {
    setIsFetching(true);
    try {
      const { data } = await API.get('/invitations?type=user');
      const invitations: Invitation[] = data.map((item: any) => fromDTO(item));
      setInvitations(invitations);
    }
    catch (err) {
      dispatch(addToast('Oops', 'We had issues loading your invitations. Try again.', 'danger'));
    }
    setIsFetching(false);
  }, [dispatch])

  useEffect(() => {
    fetchInvitations();
  }, [fetchInvitations])

  const onClickGenerateInvitation = async () => {
    if (!canGenerateInvitation) {
      return;
    }
    setIsGenerating(true);
    try {
      const { data } = await API.post('/invitations', { type: 'user' });
      const invitation = fromDTO(data);
      setInvitations(oldInvitations => [invitation].concat(...oldInvitations));
    }
    catch (err) {
      dispatch(addToast('Oops', 'We had issues generating your invitation. Try again.', 'danger'));
    }
    setIsGenerating(false);
  };

  const onClickShareInvitation = (invitation: Invitation) => {
    setShareInvitation(invitation);
  }

  return (
    <div className={styles.Root}>
      <div className="mb-3 flex flex-row justify-between items-center border-b-2 pb-3">
        <h2>Invitations</h2>
        <Button icon='plus' title="Create Invitation" onClick={onClickGenerateInvitation} isLoading={isGenerating} isDisabled={!canGenerateInvitation} />
      </div>
      <div>
        {isFetching && <Spinner />}
        {!isFetching && invitations.length === 0 && <div className="text-center p-8">You do not have any invitations to share.</div>}
        {invitations.length > 0 &&
          <DataTable value={invitations} tableStyle={{ minWidth: '50rem' }} size='small' className={styles.DataTable}>
            <Column field="token" header="Token"></Column>
            <Column field="status" header="Status" body={(invitation) => (
              <Tag
                title={invitation.status === INVITATION_STATUS.ACCEPTED ? 'Accepted' : 'Pending'}
                variant={invitation.status === INVITATION_STATUS.ACCEPTED ? 'success' : 'info'}
              />
            )}></Column>
            <Column field="created_at" header="Created" body={(invitation) => (
              <>{toDisplayDateTime(invitation.created_at)}</>
            )}></Column>
            <Column field="share" header="" body={(invitation) => (
              <Button icon='share' onClick={() => onClickShareInvitation(invitation)} outline tooltip='Share'  tooltipPosition='left' />
            )}></Column>
          </DataTable>
        }
      </div>
      <Dialog header="Share Invitation Link" visible={!!shareInvitation} style={{ width: '70vw' }} onHide={() => setShareInvitation(undefined)}>
        {shareInvitation &&
          <p className="m-0">
            <Input value={shareInvitation.url} isDisabled />
            <div className='h1 border-t my-2'></div>
            <div className="text-center">
              <FacebookShareButton url={shareInvitation.url}><FacebookIcon /></FacebookShareButton>
              <FacebookMessengerShareButton url={shareInvitation.url} appId={config.FACEBOOK_APP_ID}><FacebookMessengerIcon /></FacebookMessengerShareButton>
              <WhatsappShareButton url={shareInvitation.url}><WhatsappIcon /></WhatsappShareButton>
              <EmailShareButton url={shareInvitation.url}><EmailIcon /></EmailShareButton>
              <LinkedinShareButton url={shareInvitation.url}><LinkedinIcon /></LinkedinShareButton>
              <RedditShareButton url={shareInvitation.url}><RedditIcon /></RedditShareButton>
              <TelegramShareButton url={shareInvitation.url}><TelegramIcon /></TelegramShareButton>
              <TwitterShareButton url={shareInvitation.url}><XIcon /></TwitterShareButton>
            </div>
          </p>
        }
      </Dialog>

    </div>
  );
}

export default UserInvitations;
