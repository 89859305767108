import clsx from 'clsx';
import { Chat } from "~/store/models";
import styles from './ChatIcon.module.css';
import { ComponentProps } from '@hubblai/hubbl-ui/components/types.js';

type ChatIcon = {
  url: string,
  alt: string,
}

const ICON_LIMIT = 4;

const getChatIcons = (chat: Chat, currentUserId: string): ChatIcon[] => {
  if (chat.icon) {
    return [{
      url: chat.icon,
      alt: chat.title,
    }];
  }
  const icons: ChatIcon[] = [];
  // TODO:P2 last active at would be better
  const agents = chat.agents.sort((a1, a2) => a1.created_at - a2.created_at);
  const users = chat.users.sort((u1, u2) => u1.id.localeCompare(u2.id));
  for (const agent of agents) {
    if (icons.length === ICON_LIMIT) {
      break;
    }
    icons.push({
      url: agent.icon,
      alt: agent.getDisplayName(),
    });
  }
  let currentUserIcon: any;
  for (const user of users) {
    if (icons.length === ICON_LIMIT) {
      break;
    }
    if (user.id === currentUserId) {
      currentUserIcon = {
        url: user.avatar,
        alt: user.getDisplayName()
      };
      continue;
    }
    icons.push({
      url: user.avatar,
      alt: user.getDisplayName(),
    });
  }

  if (icons.length === 0 && currentUserIcon) {
    icons.push(currentUserIcon);
  }
  return icons;
}

type Props = {
  chat: Chat,
  currentUserId: string,
} & ComponentProps;

const ChatIcon: React.FC<Props> = ({ chat, currentUserId, className }) => {
  const icons = getChatIcons(chat, currentUserId);
  return (
    <div className={clsx(styles.ChatIcon, styles[`ChatIcon-${icons.length}`], className)}>
      {icons.map(icon =>
        <div key={icon.url} className={styles.Icon}>
          <img src={icon.url} alt={icon.url} />
        </div>
      )}
    </div>
  )
}

export default ChatIcon;
