import React from 'react';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';

import { Chat } from '~/store/models';
import styles from './ChatListItem.module.css';
import { useChatAgents, useChatUsers } from '~/store/chats/hooks';
import ChatIcon from '~/components/Chat/components/ChatIcon';
import { Icon, Badge } from '@hubblai/hubbl-ui/components/index.js';

type ChatListItemProps = {
  chat: Chat,
  currentUserId: string,
}

const ChatListItem: React.FC<ChatListItemProps> = ({ chat, currentUserId }) => {
  const params = useParams();
  const users = useChatUsers(chat.id);
  const agents = useChatAgents(chat.id);
  const isActive = params.chatId === chat.id;

  return (
    <Link to={`/chats/${chat.id}`} className={clsx(styles.ChatListItem, { [styles.active]: isActive })}>
      <div className="flex flex-row relative">
        <ChatIcon chat={chat} currentUserId={currentUserId} className={styles.ChatIcon} />
        <div className={styles.title}>
          <span className={styles.titleContent}>{chat.title}</span>
          <div className='flex flex-row justify-between flex-1 text-sm items-end'>
            <div>{users.length} <Icon name='users' className="mx-2" /></div>
            <div>{agents.length} <Icon name='add-ai' className="mx-2" /></div>
          </div>
        </div>
        {chat.isUnread() && <Badge className={styles.Badge} value="1" severity="danger" />}
      </div>
      {chat.last_message &&
        <div className={styles.lastMessage}>
          <div className={styles.content}><Icon name='message' className="mr-1 text-xs opacity-50" /> {chat.last_message}</div>
          <div className='text-sm opacity-50 text-right'>- {chat.getLastMessageAuthor()}</div>
        </div>
      }
    </Link>
  );
}

export default ChatListItem;
