import clsx from 'clsx';
import { Button, Avatar } from '@hubblai/hubbl-ui/components/index.js';
import { MessageGroup } from "~/store/messages/hooks";
import styles from './MessageGroup.module.css';

type Props = {
  group: MessageGroup,
  onClickCancel: (group: MessageGroup) => void,
  onClickReply: (group: MessageGroup) => void,
};

const MessageGroupHeader: React.FC<Props> = ({ group, onClickCancel, onClickReply }) => {
  const isTyping = group.isTyping;
  const isByCurrentUser = group.isByCurrentUser;
  const canReply = !isByCurrentUser && group.tag && !isTyping;
  const canBeCancelled = isTyping && group.isByAgent;
  return (
    <div className={clsx(styles.MessageGroupHeader, { [styles.current]: isByCurrentUser })}>
      <div className="flex flex-row items-center">
        {!isByCurrentUser && <Avatar image={group.icon} className={clsx(styles.Avatar, "mr-2")} />}
        <span className={styles.Name}>{group.name}</span>
        {isTyping && <span className={styles.Typing}> is typing...</span>}
        {isByCurrentUser && <Avatar image={group.icon} className={clsx(styles.Avatar, "ml-2")} />}
      </div>
      <div className='flex flex-row'>
        {canBeCancelled && <Button onClick={() => onClickCancel(group)} icon="stop" className='glass-button mr-1' size="sm" tooltip='Stop' />}
        {canReply && <Button onClick={() => onClickReply(group)} icon="reply" className="glass-button" size="sm" tooltip="Reply" />}
      </div>
    </div>
  )
}

export default MessageGroupHeader;
