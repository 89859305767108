import update from 'immutability-helper';
import { RESET, Action } from '@hubblai/hubbl-ui/store/types.js';
import * as types from './types';
import { Chat, Message } from '~/store/models';
import { Pagination } from '~/store/types';
import config from '~/config';

export type ChatMap = {
  [id: string]: Chat
}

export type ChatsState = {
  isFetchingAll: boolean,
  isFetchingOne: boolean,
  isCreating: boolean,
  pagination: Pagination,
  chats: ChatMap,
}

const INITIAL_STATE: ChatsState = {
  isFetchingAll: false,
  isCreating: false,
  isFetchingOne: false,
  pagination: {
    limit: config.FETCH_CHATS_DEFAULT_LIMIT,
    hasNextPage: false,
    cursor: undefined
  },
  chats: {},
}

const updateChat = (state: ChatsState, chatId: string, data: any) => {
  if (state.chats[chatId]) {
    return update(state, {
      chats: {
        [chatId]: { $merge: data }
      }
    });
  }
  return state;
}

const updateChatLastMessage = (state: ChatsState, chatId: string, message: Message) => {
  if (state.chats[chatId]) {
    return update(state, {
      chats: {
        [chatId]: {
          last_message_by: { $set: message.user_id || message.agent_id },
          last_message_at: { $set: message.created_at },
          // TODO:P2 would be good for the content not to contain tags and new lines and be short...
          last_message: { $set: message.isText() ? message.content : '[Attachments]' },
        }
      }
    });
  }
  return state;
}

const reducer = (state = { ...INITIAL_STATE }, { type, payload }: Action) => {
  switch (type) {
    case types.FETCH_CHATS_REQUEST_START: {
      return update(state, {
        isFetchingAll: { $set: true },
      })
    }
    case types.FETCH_CHATS_REQUEST_SUCCESS: {
      const chats: ChatMap = payload.chats.reduce((acc: ChatMap, chat: Chat) => {
        acc[chat.id] = chat
        return acc;
      }, {});

      return update(state, {
        isFetchingAll: { $set: false },
        pagination: { $set: payload.pagination },
        chats: { $merge: chats },
      })
    }

    case types.FETCH_CHATS_REQUEST_FAILURE:
      return update(state, {
        isFetchingAll: { $set: false },
      })

    case types.FETCH_CHAT_REQUEST_START:
      return update(state, {
        isFetchingOne: { $set: true },
      })

    case types.FETCH_CHAT_REQUEST_SUCCESS: {
      const { chat } = payload;
      return update(state, {
        isFetchingOne: { $set: false },
        chats: {
          [chat.id]: { $set: chat },
        }
      });
    }

    case types.FETCH_CHAT_REQUEST_FAILURE: {
      return update(state, {
        isFetchingOne: { $set: false },
      })
    }

    case types.CREATE_CHAT_REQUEST_START:
      return update(state, {
        isCreating: { $set: true },
      })

    case types.CREATE_CHAT_REQUEST_SUCCESS: {
      const { chat } = payload;
      return update(state, {
        isCreating: { $set: false },
        chats: {
          [chat.id]: { $set: chat },
        }
      });
    }

    case types.CREATE_CHAT_REQUEST_FAILURE:
      return update(state, {
        isCreating: { $set: false },
      })

    case types.ON_LOCAL_MESSAGE_RECEIVED:
    case types.ON_MESSAGE_RECEIVED:
    case types.ON_MESSAGE_UPDATED: {
      const { chatId, isLastMessage, message } = payload;
      if (isLastMessage && state.chats[chatId]) {
        return updateChatLastMessage(state, chatId, message as Message);
      }
      return state;
    }
    case types.ON_MESSAGE_CONTENT_UPDATED: {
      const { message, isLastMessage, chatId } = payload;
      if (isLastMessage) {
        return updateChatLastMessage(state, chatId, message);
      }
      return state;
    }

    case types.LEAVE_CHAT_REQUEST_START: {
      const { chatId } = payload;
      if (state.chats[chatId]) {
        const chats: ChatMap = { ...state.chats };
        delete chats[chatId];
        return {
          ...state,
          chats,
        }
      }
      return state
    }
    case types.LEAVE_CHAT_REQUEST_FAILURE:
      return state;

    case types.MARK_AS_READ_START: {
      return updateChat(state, payload.chatId, {
        last_seen_at: payload.last_seen_at,
      });
    }

    case types.EDIT_CHAT_START: {
      return updateChat(state, payload.chatId, {
        ...payload.data
      });
    }

    case types.ON_CHAT_AGENT.ADDED: {
      const { chatId, agent } = payload;
      if (state.chats[chatId]) {
        const agentIndex = state.chats[chatId].agents.findIndex(a => a.id === agent.id);
        if (agentIndex === -1) {
          return update(state, {
            chats: {
              [chatId]: {
                agents: { $push: [agent] },
              }
            }
          });
        }
      }
      return state;
    }
    case types.ON_CHAT_AGENT.REMOVED: {
      const { chatId, id: agentId } = payload;
      if (state.chats[chatId]) {
        const agentIndex = state.chats[chatId].agents.findIndex(agent => agent.id === agentId);
        if (agentIndex > -1) {
          return update(state, {
            chats: {
              [chatId]: {
                agents: { $splice: [[agentIndex, 1]] },
              }
            }
          });
        }
      }
      return state;
    }
    case types.ON_CHAT_USER.ADDED: {
      const { chatId, user } = payload;
      if (state.chats[chatId]) {
        const userIndex = state.chats[chatId].users.findIndex(u => u.id === user.id);
        if (userIndex === -1) {
          return update(state, {
            chats: {
              [chatId]: {
                users: { $push: [user] },
              }
            }
          });
        }
      }
      return state;
    }
    case types.ON_CHAT_USER.REMOVED: {
      const { chatId, id: userId } = payload;
      if (state.chats[chatId]) {
        const userIndex = state.chats[chatId].users.findIndex(user => user.id === userId);
        if (userIndex > -1) {
          return update(state, {
            chats: {
              [chatId]: {
                users: { $splice: [[userIndex, 1]] },
              }
            }
          });
        }
      }
      return state;
    }
    case RESET:
      return { ...INITIAL_STATE };
    case types.LEAVE_CHAT_REQUEST_SUCCESS:
    default:
      return state;
  }
};


export default reducer;
