import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useIsLoggedIn, useUser } from '@hubblai/hubbl-ui/store/auth/hooks.js';
import API from '@hubblai/hubbl-ui/lib/api.js';
import { useAppDispatch } from '@hubblai/hubbl-ui/store/index.js';
import { addToast } from '@hubblai/hubbl-ui/store/notifications/actions.js';
import { useToasts } from '@hubblai/hubbl-ui/store/notifications/hooks.js';
import { Toaster } from '@hubblai/hubbl-ui/components/index.js';
import { useQuery } from '@hubblai/hubbl-ui/hooks/index.js';

import { fetchChats } from '~/store/chats/actions';
import styles from './App.module.css';

import TopBar from '~/components/TopBar';

import { ConfirmDialog } from 'primereact/confirmdialog';


const App: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useUser();
  const query = useQuery();
  const isLoggedIn = useIsLoggedIn();
  const toasts = useToasts();
  const redirect = query.get('redirect');

  useEffect(() => {
    if (isLoggedIn && redirect) {
      navigate(redirect);
    }
  }, [isLoggedIn, redirect, navigate])

  useEffect(() => {
    dispatch(fetchChats());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      API.subscribe(`/users/${user.id}`, '/notifications', (data: any) => {
        if (data.type === 'toast') {
          dispatch(addToast(data.title, data.message, data.variant));
        }
      });
    }
  }, [user, dispatch]);

  return (
    <div className={styles.App}>
      <TopBar />
      <div className={styles.AppContent}>
        <Outlet />
      </div>
      <Toaster toasts={toasts} />
      <ConfirmDialog />
    </div>
  );
}

export default App;
