import { HTTP_METHOD } from '@hubblai/hubbl-ui/lib/api.js';
import { perform } from '@hubblai/hubbl-ui/store/actions.js'
import { Dispatch } from 'redux';
import * as types from './types';
import { Agent } from '../models';

export const fetchAgents = (query?: string) => async (dispatch: Dispatch) => {
  return perform<Agent[]>(
    dispatch,
    types.FETCH_AGENTS_REQUEST,
    {
      method: HTTP_METHOD.GET,
      url: `/agents?query=${query}`,
    },
    Agent.fromDTOs,
    {
      query
    }
  );
}
