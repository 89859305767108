import EventEmitter from 'eventemitter3';
import API from '@hubblai/hubbl-ui/lib/api.js';

const streams: { [url: string]: APIStream } = {};

export class APIStream {

  emitter = new EventEmitter();
  url: string;

  constructor(url: string) {
    this.url = url;
  }

  static for(url: string) {
    if (!streams[url]) {
      streams[url] = new APIStream(url);
    }
    return streams[url];
  }

  connect() {
    API.stream(this.url,
      (chunk: string) => {
        this.emitter.emit('chunk', chunk);
      },
      (content: string) => {
        this.emitter.emit('completed', content);
      }
    );
  }

  // TODO:P2 should think about when does this disconnect

  on(eventName: string, callback: any) {
    this.emitter.on(eventName, callback);
  }

  emit(eventName: string, payload?: any) {
    this.emitter.emit(eventName, payload);
  }
}
