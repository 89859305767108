import React, { useCallback, useEffect, useState } from 'react';
import { Button, Input, Avatar, Message } from '@hubblai/hubbl-ui/components/index.js';
import styles from './UserProfile.module.css';
import ImageUpload from '~/components/ImageUpload';
import { useAppDispatch } from '@hubblai/hubbl-ui/store/index.js';
import { isEmailValid } from '@hubblai/hubbl-core/lib/validator.js';
import { getDisplayError } from '@hubblai/hubbl-ui/lib/api.js';
import { useUser } from '@hubblai/hubbl-ui/store/auth/hooks.js';
import { updateUser, uploadUserAvatar } from '@hubblai/hubbl-ui/store/auth/actions.js';
import { addToast } from '@hubblai/hubbl-ui/store/notifications/actions.js';
import { FormErrors } from '@hubblai/hubbl-ui/components/types.js';

const UserProfile: React.FC = () => {
  const user = useUser()!;
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errors, setErrors] = useState<FormErrors>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingAvatar, setIsEditingAvatar] = useState(false);
  const [file, setFile] = useState<File | undefined>();
  const [avatarUrl, setAvatarUrl] = useState('');

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setFirstName(user.first_name);
      setLastName(user.last_name);
    }
  }, [user]);

  const getChangedData = useCallback(() => {
    const data: any = {};
    if (email !== user?.email) {
      data['email'] = email;
    }
    if (firstName !== user?.first_name) {
      data['first_name'] = firstName;
    }
    if (lastName !== user?.last_name) {
      data['last_name'] = lastName;
    }
    if (avatarUrl) {
      data['avatar'] = avatarUrl;
    }
    return data;
  }, [user, email, firstName, lastName, avatarUrl]);

  const isValid = () => {
    const errors: any = {};
    if (!isEmailValid(email)) {
      errors['email'] = 'Invalid email';
    }
    if (firstName.length === 0) {
      errors['first_name'] = 'First Name is required';
    }
    if (lastName.length === 0) {
      errors['last_name'] = 'Last Name is required';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const onClickSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    setErrorMessage('');
    if (isValid()) {
      setIsLoading(true);
      try {
        const changedData = getChangedData();
        if (Object.keys(changedData).length > 0) {
          await dispatch(updateUser(changedData));
        }
        if (file) {
          await dispatch(uploadUserAvatar(file));
        }
        dispatch(addToast('Done!', 'Your profile was updated', 'success'));
      }
      catch (err) {
        const displayError = getDisplayError(err);
        setErrorMessage(displayError);
      }
      setIsLoading(false);
    }
  }

  const canSave = useCallback(() => {
    return file || Object.keys(getChangedData()).length > 0;
  }, [file, getChangedData])

  const onClickEditAvatar = () => {
    setIsEditingAvatar(true);
  };

  const onClickConfirmAvatar = async (file?: File, imageUrl?: string) => {
    if (file) {
      setFile(file);
      setAvatarUrl('');
    } else if (imageUrl) {
      setAvatarUrl(imageUrl);
      setFile(undefined);
    }
    setIsEditingAvatar(false);
  };

  const onClickCancelEditAvatar = () => {
    setIsEditingAvatar(false);
  };

  const getAvatar = useCallback(() => {
    if (file) {
      return URL.createObjectURL(file);
    }
    if (avatarUrl) {
      return avatarUrl;
    }
    return user.getAvatar();
  }, [file, avatarUrl, user]);

  const onFirstNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value);
  const onLastNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value);
  const onEmailChanged = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  return (
    <div className={styles.Root}>
      <div className="p-4 h-full">
        <div className="mb-3">
          <h4 className="mb-3">Avatar</h4>
          {isEditingAvatar && (
            <ImageUpload className={styles.ImageUpload}  buttonTitleConfirm='Confirm Avatar' onClickConfirm={onClickConfirmAvatar} onClickCancel={onClickCancelEditAvatar} />
          )}
          {!isEditingAvatar && (
            <div className="inline-flex relative p-2">
              <Avatar image={getAvatar()} className={styles.Avatar} />
              <Button onClick={onClickEditAvatar} icon='edit' className={styles.AvatarEditButton} tooltip='Change' rounded />
            </div>
          )}
        </div>
        <div className="flex flex-row items-center mb-3">
          <Input label="First Name" id="first_name" placeholder="First Name" className="flex-1 mr-1" value={firstName} onChange={onFirstNameChanged} error={errors['first_name']} />
          <Input label="Last Name" id="last_name" placeholder="Last Name" className="flex-1 ml-1" value={lastName} onChange={onLastNameChanged} error={errors['last_name']} />
        </div>

        <div>
          <Input label="Email" id="email" type="text" placeholder="Email address" className="w-full mb-3" value={email} onChange={onEmailChanged} error={errors['email']} />
          {errorMessage && <Message className='mb-4 w-full' severity="error" text={errorMessage} />}
        </div>
      </div>

      <div className={styles.Menu}>
        <div></div>
        <Button title="Save Changes" type="submit" icon="save" variant='success' onClick={onClickSave} isLoading={isLoading} isDisabled={!canSave} />
      </div>
    </div>
  );
}

export default UserProfile;
