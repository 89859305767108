import { useSelector } from 'react-redux';
import { memoize } from 'proxy-memoize';
import { ChatsState } from './reducer';
import { User } from '@hubblai/hubbl-ui/store/models.js';
import { Agent, Chat } from '~/store/models';
import { isEmpty } from '@hubblai/hubbl-core/lib/object.js';

type ChatsStoreType = {
  chats: ChatsState,
}

const emptyChat: Chat[] = [];
const emptyUsers: User[] = [];
const emptyAgents: User[] = [];

const selectChats = (state: ChatsStoreType) => isEmpty(state.chats.chats) ? emptyChat : Object.values(state.chats.chats).sort((c1, c2) => {
  return c2.last_message_at - c1.last_message_at;
});

type SelectOtherUserType = {
  state: ChatsStoreType,
  chatId: string,
  currentUserId: string,
}

const selectOtherUsers = memoize<SelectOtherUserType, User[]>(({ state, chatId, currentUserId }): User[] => state.chats.chats[chatId]?.users.filter((user: User) => user.id !== currentUserId) || emptyUsers);

export const useChats = () => useSelector<ChatsStoreType, Chat[]>(memoize(selectChats));

export const useIsFetchingAll = () => useSelector<ChatsStoreType, boolean>(state => state.chats.isFetchingAll);
export const useIsFetchingOne = () => useSelector<ChatsStoreType, boolean>(state => state.chats.isFetchingOne);
export const useHasNextPage = () => useSelector<ChatsStoreType, boolean>(state => state.chats.pagination.hasNextPage);
export const useIsCreating = () => useSelector<ChatsStoreType, boolean>(state => state.chats.isCreating);

export const useChat = (chatId: string) => useSelector<ChatsStoreType, Chat | undefined>(state => state.chats.chats[chatId]);

export const useChatUsers = (chatId: string) => useSelector<ChatsStoreType, User[]>(
  state => state.chats.chats[chatId]?.users || emptyUsers
);
export const useChatOtherUsers = (chatId: string, currentUserId: string) => useSelector<ChatsStoreType, User[]>(state => selectOtherUsers({ state, chatId, currentUserId }));
export const useChatAgents = (chatId: string) => useSelector<ChatsStoreType, Agent[]>(
  state => state.chats.chats[chatId]?.agents || emptyAgents
);
