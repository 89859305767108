import { Badge, Icon, Input, Spinner } from '@hubblai/hubbl-ui/components/index.js';
import { useChats, useIsFetchingAll } from '~/store/chats/hooks';
import styles from './Home.module.css';
import { Chat } from '~/store/models';
import { useCurrentUserId } from '@hubblai/hubbl-ui/store/auth/hooks.js';
import { useNavigate } from 'react-router-dom';
import ChatIcon from '~/components/Chat/components/ChatIcon';
import { useAppDispatch } from '@hubblai/hubbl-ui/store/index.js';
import { createChat } from '~/store/chats/actions';

type Props = {
  chat: Chat,
  currentUserId: string,
  onClick: () => void,
};

const ChatItem: React.FC<Props> = ({ chat, currentUserId, onClick }) => {
  return (
    <div className={styles.ChatItem} onClick={onClick}>
      <ChatIcon chat={chat} currentUserId={currentUserId} className={styles.ChatIcon} />
      <div className={styles.Title}>
        <span>{chat.title}</span>
      </div>
      {chat.isUnread() && <Badge className={styles.Badge} value="1" severity="danger" />}
    </div>
  )
}

const Home: React.FC = () => {
  const dispatch = useAppDispatch();
  const isFetching = useIsFetchingAll();
  const chats = useChats();
  const userId = useCurrentUserId();
  const navigate = useNavigate();

  const onClickAdd = async () => {
    const newChat = await dispatch(createChat());
    navigate(`/chats/${newChat.id}`);
  }

  const onClickChat = (chatId: string) => {
    navigate(`/chats/${chatId}`);
  }

  return (
    <div className={styles.Root}>
      {isFetching && <Spinner />}
      {!isFetching && chats.length > 0 && (
        <div className='flex flex-col items-center overflow-y-auto'>
          <Input placeholder='Search...' icon='search' className={styles.Search} inputClassName={styles.SearchInput} />
          <div className={styles.ChatItems}>
            {chats.map(chat => <ChatItem key={chat.id} chat={chat} currentUserId={userId} onClick={() => onClickChat(chat.id)} />)}
            <div className={styles.Add} onClick={onClickAdd}>
              <Icon name='plus' className={styles.AddIcon} />
            </div>
          </div>
        </div>
      )}
      {!isFetching && chats.length === 0 && (
        <div className='w-full h-full flex items-center justify-center'>
          <div className="rounded-xl border-dotted text-white border-2 flex flex-col items-center justify-center p-6 hover:border-solid cursor-pointer" onClick={onClickAdd}>
            <Icon name='message' className="mb-3 text-xl" />
            <span>Start your first Chat</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default Home;
