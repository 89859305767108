import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InvitationView from "~/components/Invitation/InvitationView";
import API from "@hubblai/hubbl-ui/lib/api.js";
import { useAppDispatch } from "@hubblai/hubbl-ui/store/index.js";
import { Invitation } from "~/store/models";
import { addToast } from "@hubblai/hubbl-ui/store/notifications/actions.js";
import { ENTITY_TYPE } from '@hubblai/hubbl-core/models/Invitation.js';
import { Modal, ButtonItem } from "@hubblai/hubbl-ui/components/index.js";

const getEntityRedirectUrl = (invitation: Invitation): string | undefined => {
  if (invitation.entityType === ENTITY_TYPE.Chat) {
    return `/chats/${invitation.entityId}`;
  }
  return undefined;
}

const InvitationModal: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const token = params.token!;
  const [isAccepting, setIsAccepting] = useState(false);
  const [isOpened, setIsOpened] = useState(true);

  const onClickAcceptInvitation = useCallback(async () => {
    setIsAccepting(true);
    try {
      const { data } = await API.put(`/invitations/${token}`, {});
      if (data) {
        const invitation = Invitation.fromDTO(data);
        const redirectUrl = getEntityRedirectUrl(invitation);
        if (redirectUrl) {
          navigate(redirectUrl);
          setIsOpened(false);
        }
      }
    }
    catch {
      dispatch(addToast('Error', 'There was a problem accepting your invitation. Please, try again.'));
    }
    setIsAccepting(false);
  }, [setIsAccepting, token, navigate, dispatch]);

  const buttons: ButtonItem[] = [{
    title: "Accept",
    onClick: onClickAcceptInvitation,
    variant: 'info',
    isLoading: isAccepting,
  }];

  return (
    <Modal buttons={buttons} navigate={navigate} title={`You were invited!`} isOpened={isOpened}>
      <InvitationView token={token} />
    </Modal >
  )
}

export default InvitationModal;
