import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import styles from './ImageUpload.module.css';
import { Button, Icon, Input, Message } from '@hubblai/hubbl-ui/components/index.js';
import { ClassName, ComponentProps, FormErrors } from '@hubblai/hubbl-ui/components/types.js';
import { useDropzone } from 'react-dropzone';
import API, { getDisplayError } from '@hubblai/hubbl-ui/lib/api.js';
import config from '~/config';

type Props = {
  className?: ClassName,
  title?: string,
  buttonTitleConfirm?: string,
  buttonTitleCancel?: string,
  onClickConfirm?: (file?: File, imageUrl?: string) => void,
  onClickCancel?: () => void,
  onImageChanged?: (url: string) => void,
  onFileChanged?: (file?: File) => void,
  error?: string,
} & ComponentProps;

type PromptImage = {
  url: string,
}

const ImageUpload: React.FC<Props> = ({ title, className, buttonTitleConfirm = 'Confirm', buttonTitleCancel = 'Cancel', onClickConfirm, onClickCancel, onImageChanged, onFileChanged, error }) => {
  const agentId = config.AGENTS.AVATAR_GENERATE_AGENT_ID;
  const [prompt, setPrompt] = useState('');
  const [file, setFile] = useState<File | undefined>();
  const [errors, setErrors] = useState<FormErrors>({});
  const [imageIndex, setImageIndex] = useState(-1);
  const [errorMessage, setErrorMessage] = useState('');
  const [filePreview, setFilePreview] = useState('');
  const [promptImages, setPromptImages] = useState<PromptImage[]>([]);
  const [isGenerating, setIsGenerating] = useState(false);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const selectedFile = acceptedFiles[0];
    // TODO:P2 validate file size
    setFile(selectedFile);
  }, []);

  useEffect(() => {
    if (file) {
      setFilePreview(URL.createObjectURL(file));
      setImageIndex(-1);
      if (onFileChanged) {
        onFileChanged(file);
      }
    } else {
      setFilePreview('');
      if (onFileChanged) {
        onFileChanged();
      }
    }
  }, [file, onFileChanged]);

  useEffect(() => {
    if (onImageChanged) {
      const imageUrl = promptImages[imageIndex]?.url;
      onImageChanged(imageUrl);
    }
  }, [imageIndex, onImageChanged, promptImages])

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: { 'image/*': [] }
  });

  const onPromptChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(e.target.value);
  }

  const onClickCancelFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setFile(undefined);
  }

  const isValid = () => {
    const errors: any = {};
    if (prompt.length === 0) {
      errors['prompt'] = 'Prompt required';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const onClickGenerateImage = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isGenerating) {
      return;
    }
    setErrorMessage('');
    if (isValid()) {
      setIsGenerating(true);
      try {
        const result = await API.quickinvoke(agentId, prompt);
        if (result?.reply?.user?.files?.length) {
          const url = result.reply?.user?.files[0].url;
          setPromptImages(oldPromptImages => [{
            url
          }].concat(...oldPromptImages));
          setImageIndex(0);
        } else if (result?.reply?.user?.content) {
          setErrorMessage(result.reply?.user?.content);
        } else {
          setErrorMessage('No images were generated, please, try again');
        }
      }
      catch (err) {
        const displayError = getDisplayError(err);
        setErrorMessage(displayError);
      }
      setIsGenerating(false);
    }
  }

  const onClickPromptImage = (index: number) => {
    setImageIndex(index);
  }

  const canConfirm = file !== undefined || imageIndex > -1;
  const isUploadingImage = file !== undefined;

  return (
    <div className={clsx(styles.Root, className)}>
      {title && <h4 className='mb-3'>{title}</h4>}
      <div className={styles.Content}>
        <div className={clsx(styles.Dropzone, { [styles.active]: isUploadingImage })} {...getRootProps()}>
          <input {...getInputProps()} />
          {!file && (
            <>
              <div className='py-3'>
                <Icon name="image" className={styles.ImageIcon} />
              </div>
              <p>Drag 'n' drop some image here, or click to select one</p>
            </>
          )}
          {file && filePreview.length > 0 && (
            <>
              <div className={styles.Preview}>
                <img src={filePreview} />
              </div>
              <div className="flex flex-row items-center">
                <p>{file.name}</p>
                <Button className="ml-3" variant='danger' outline icon="cancel" onClick={onClickCancelFile} />
              </div>
            </>
          )}

        </div>
        <div className={styles.Or}><span>OR</span></div>
        <div className={styles.Prompt}>
          <form>
            <div className={styles.PromptInput}>
              <Input className='flex-1' placeholder="Describe your Avatar" value={prompt} onChange={onPromptChanged} error={errors['prompt']} />
              <Button icon="generate" type="submit" className='ml-3' title="Generate" onClick={onClickGenerateImage} isLoading={isGenerating} />
            </div>
          </form>
          <div className='flex flex-1'>
            <div className={styles.PromptImages}>
              {promptImages.map((img, index) => (
                <div onClick={() => onClickPromptImage(index)} key={img.url} className={clsx(styles.PromptImage, { [styles.active]: imageIndex === index })}>
                  <img src={img.url}></img>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {(errorMessage || error) && <Message className='mb-3 w-full' severity="error" text={errorMessage || error} />}

      {onClickCancel && onClickConfirm && (
        <div className={styles.Menu}>
          <Button variant='secondary' onClick={onClickCancel} icon='cancel' title={buttonTitleCancel} />
          <Button variant='success' title={buttonTitleConfirm} icon='check' isDisabled={!canConfirm} onClick={() => onClickConfirm(file, promptImages[imageIndex]?.url)} />
        </div>
      )}
    </div>
  );
}

export default ImageUpload;
