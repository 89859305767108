import { useSelector } from 'react-redux';
import { MessageContent, MessagesState } from './reducer';
import { Message } from '~/store/models';
import { MESSAGE_STATUS } from '@hubblai/hubbl-core/models/Message.js';

type MessagesStoreType = {
  messages: MessagesState,
}

export type MessageGroup = {
  id: string,
  name: string,
  icon?: string,
  tag?: string,
  isByAgent: boolean,
  isTyping: boolean,
  isByCurrentUser: boolean,
  items: Message[],
}
const empty: Message[] = [];
const emptyMessageGroup: MessageGroup[] = [];
const emptyNewMessage: MessageContent = {
  content: ''
}

const selectMessages = (chatId: string) => (state: MessagesStoreType) => state.messages.messages[chatId] ? state.messages.messages[chatId].items : empty;

export const useMessages = (chatId: string) => useSelector<MessagesStoreType, Message[]>(selectMessages(chatId));

export const useMessagesLength = (chatId: string) => useSelector<MessagesStoreType, number>((state: MessagesStoreType) => state.messages.messages[chatId]?.items.length || 0);

export const useStreamedMessages = (chatId: string) => useSelector<MessagesStoreType, Message[]>(
  (state: MessagesStoreType) => state.messages.messages[chatId]?.items.filter(message => message.isStreaming()) || empty,
  (messages1, messages2) => {
    if (messages1.length !== messages2.length) {
      return false;
    }
    for (let i = 0; i < messages1.length; i++) {
      const m1 = messages1[i];
      const m2 = messages2[i];
      if (m1.id !== m2.id || m1.getContent() !== m2.getContent()) {
        return false;
      }
    }
    return true;
  }
);

export const useMessageGroups = (chatId: string, currentUserId: string) => useSelector<MessagesStoreType, MessageGroup[]>((state: MessagesStoreType) => {
  if (state.messages.messages[chatId]) {
    const groups: MessageGroup[] = [];
    let groupIndex = -1;
    const messages = state.messages.messages[chatId].items.sort((m1, m2) => m1.created_at - m2.created_at);

    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      const groupId = message.getGroupId();
      if (i === 0 || messages[i - 1].getGroupId() !== groupId) {
        groupIndex++;
        groups.push({
          id: `${groupId}-${groupIndex}`,
          icon: message.getAuthorIcon(),
          name: message.getGroupName(),
          tag: message.getAuthorTag(),
          isByAgent: message.isByAgent(),
          isByCurrentUser: message.isByUser(currentUserId),
          items: [message],
          isTyping: message.status === MESSAGE_STATUS.TYPING,
        });
      } else {
        groups[groupIndex].isTyping = groups[groupIndex].isTyping || message.status === MESSAGE_STATUS.TYPING;
        groups[groupIndex].items.push(message);
      }
    }
    return groups;
  }
  return emptyMessageGroup;
});

// const selectMessages = (chatId: string) => (state: MessagesStoreType) => state.messages.messages[chatId];

export const useIsSubmitting = () => useSelector<MessagesStoreType, boolean>(state => state.messages.isSubmitting);

export const useIsFetching = (chatId: string) => useSelector<MessagesStoreType, boolean>(state => state.messages.messages[chatId]?.isFetching);
export const useHasMountFetched = (chatId: string) => useSelector<MessagesStoreType, boolean>(state => state.messages.messages[chatId]?.hasMountFetched || false);

export const useHasNextPage = (chatId: string) => useSelector<MessagesStoreType, boolean>(state => state.messages.messages[chatId]?.pagination.hasNextPage);

export const useNewMessage = (chatId: string) => useSelector<MessagesStoreType, MessageContent>(state => state.messages.newMessages[chatId] || emptyNewMessage);
