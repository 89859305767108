export const SUBMIT_MESSAGE_REQUEST_START = "SUBMIT_MESSAGE_REQUEST_START";
export const SUBMIT_MESSAGE_REQUEST_SUCCESS = "SUBMIT_MESSAGE_REQUEST_SUCCESS";
export const SUBMIT_MESSAGE_REQUEST_FAILURE = "SUBMIT_MESSAGE_REQUEST_FAILURE";

export const FETCH_MESSAGES_REQUEST_START = "FETCH_MESSAGES_REQUEST_START";
export const FETCH_MESSAGES_REQUEST_SUCCESS = "FETCH_MESSAGES_REQUEST_SUCCESS";
export const FETCH_MESSAGES_REQUEST_FAILURE = "FETCH_MESSAGES_REQUEST_FAILURE";

export const ON_MESSAGE_RECEIVED = 'ON_MESSAGE_RECEIVED';
export const ON_LOCAL_MESSAGE_RECEIVED = 'ON_LOCAL_MESSAGE_RECEIVED';
export const ON_MESSAGE_UPDATED = 'ON_MESSAGE_UPDATED';
export const ON_MESSAGE_CONTENT_UPDATED = 'ON_MESSAGE_CONTENT_UPDATED';

export const SET_NEW_MESSAGE = "SET_NEW_MESSAGE";
export const APPEND_NEW_MESSAGE = "APPEND_NEW_MESSAGE";
export const CLEAR_NEW_MESSAGE = "CLEAR_NEW_MESSAGE";

export const UPDATE_MESSAGE_REQUEST = {
  START: "UPDATE_MESSAGE_REQUEST.START",
  SUCCESS: "UPDATE_MESSAGE_REQUEST.SUCCESS",
  FAILURE: "UPDATE_MESSAGE_REQUEST.FAILURE",
}
