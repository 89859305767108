import App from './App';
import { Navigate } from 'react-router-dom';

import Home from '~/components/Home';
import Chat from '~/components/Chat';
import UserProfile from '~/components/UserProfile';
import InviteAIModal from '~/components/modals/InviteAIModal';
import InviteUserModal from '~/components/modals/InviteUserModal';
import InvitationModal from './components/modals/InvitationModal';

import ChatsLayout from './layouts/Chats';
import UsersLayout from './layouts/Users';
import UserInvitations from './components/UserInvitations';
import DebugModal from './components/modals/DebugModal/DebugModal';

export default [
  {
    element: <App />,
    children: [
      { path: '/', element: <Home /> },
      {
        element: <ChatsLayout />,
        children: [{
          path: '/chats/:chatId',
          element: <Chat />,
          children: [
            { path: 'invite/users', element: <InviteUserModal /> },
            { path: 'invite/ai', element: <InviteAIModal /> },
            { path: 'debug', element: <DebugModal /> }
          ],
        }, {
          path: '/invite/:token', element: <InvitationModal />
        }],
      },
      {
        element: <UsersLayout />,
        children: [
          { path: '/users/:userId', element: <UserProfile /> },
          { path: '/users/:userId/invitations', element: <UserInvitations /> },
        ],
      },
      { path: '*', element: <Navigate to="/" replace /> },
    ]
  },
];
