import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import styles from './Chat.module.css';
import { Button, Spinner, Input, DropdownMenu, DropdownMenuItem } from '@hubblai/hubbl-ui/components/index.js';
import { useChat, useIsFetchingAll, useIsFetchingOne } from '~/store/chats/hooks';
import MessageList from './components/MessageList';
import MessageForm from './components/MessageForm';
import { useAppDispatch } from '@hubblai/hubbl-ui/store/index.js';
import { leaveChat, fetchChat, updateChat } from '~/store/chats/actions';
import { Chat } from '~/store/models';
import { useCurrentUserId, usePreferences } from '@hubblai/hubbl-ui/store/auth/hooks.js';
import { updatePreferences } from '@hubblai/hubbl-ui/store/auth/actions.js';
import { confirmDialog } from 'primereact/confirmdialog';
import API, { getDisplayError } from '@hubblai/hubbl-ui/lib/api.js';
import { addToast } from '@hubblai/hubbl-ui/store/notifications/actions.js';

type ChatHeaderProps = {
  chat: Chat,
}

const ChatHeader: React.FC<ChatHeaderProps> = ({ chat }) => {
  const params = useParams();
  const chatId = params.chatId!;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState("");
  const preferences = usePreferences();

  const onClickLeave = () => {
    dispatch(leaveChat(chatId));
    navigate('/');
  }

  const onClickToggleDisplayCosts = () => {
    dispatch(updatePreferences('displayMessageCost', !preferences.displayMessageCost));
  }

  const onClickResetSessionAccept = async () => {
    try {
      await API.delete(`/chats/${chatId}/session`);
    }
    catch (err) {
      const errorMessage = getDisplayError(err);
      dispatch(addToast('Oops', 'Error resetting session: ' + errorMessage, 'danger'));
    }
  }

  const onClickResetSession = () => {
    confirmDialog({
      message:
        <>
          <div>Are you sure you want to proceed to reset the session?</div>
          <div>All Agents will lose all their knowledge.</div>
        </>
      ,
      header: 'Reset Session',
      icon: 'pi pi-exclamation-triangle',
      accept: onClickResetSessionAccept,
    });

  }

  const updateChatConfig = (data: any) => {
    dispatch(updateChat(chatId, {
      config: {
        ...chat.config,
        ...data,
      }
    }));
  }

  const onClickAddUser = () => {
    navigate(`/chats/${chatId}/invite/users`)
  }

  const onClickInviteAgent = () => {
    navigate(`/chats/${chatId}/invite/ai`)
  }

  const onClickToggleKits = () => {
    updateChatConfig({
      canUseKits: !chat.config.canUseKits,
    });
  }

  const onClickToggleHistory = () => {
    updateChatConfig({
      hasMemory: !chat.config.hasMemory,
    });
  }

  const onClickToggleShowKitsExecution = () => {
    updateChatConfig({
      showKitsExecution: !chat.config.showKitsExecution,
    });
  }

  const onClickEdit = () => {
    setIsEditing(true);
  }

  const items: DropdownMenuItem[] = [
    {
      icon: 'edit',
      label: "Rename",
      onClick: onClickEdit,
    },
    {
      label: '-'
    },
    {
      icon: 'settings',
      label: 'Settings',
      items: [
        {
          icon: 'kits',
          label: chat.config.canUseKits ? 'Disable Kits' : 'Enable Kits',
          onClick: onClickToggleKits,
        },
        {
          icon: 'memory',
          label: chat.config.hasMemory ? 'Disable History' : 'Enable History',
          onClick: onClickToggleHistory,
        },
        {
          icon: 'eye',
          label: chat.config.showKitsExecution ? 'Hide Kits Execution' : 'Show Kits Execution',
          onClick: onClickToggleShowKitsExecution,
        },
        {
          icon: 'dollar',
          label: preferences.displayMessageCost ? "Hide Costs" : "Show Costs",
          onClick: onClickToggleDisplayCosts,
        },
        {
          icon: 'cancel',
          label: "Reset Session",
          onClick: onClickResetSession,
        },
      ]
    },
    {
      label: '-'
    },
    {
      icon: 'add-user',
      label: 'Invite',
      items: [
        {
          icon: 'add-user',
          label: "User",
          onClick: onClickAddUser,
        },
        {
          icon: 'add-ai',
          label: "Agent",
          onClick: onClickInviteAgent,
        },
      ]
    },
    {
      label: '-'
    },
    {
      icon: 'leave',
      label: "Leave",
      onClick: onClickLeave,
    }
  ]

  useEffect(() => {
    setTitle(chat.title);
    setIsEditing(false);
  }, [chat]);

  const onClickCancel = () => {
    setIsEditing(false);
  }

  const onClickSave = () => {
    if (title !== chat.title && title.length > 0) {
      dispatch(updateChat(chat.id, { title }));
    }
    setIsEditing(false);
  }

  const onChangeEditTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  }

  const onInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onClickSave();
    }
  }

  const onClickDebug = () => {
    navigate(`/chats/${chatId}/debug`);
  }

  return (
    <div className={styles.ChatHeader}>
      <div className='flex flex-row items-center mr-2'>
        {isEditing &&
          <>
            <Input value={title} onChange={onChangeEditTitle} onKeyPress={onInputKeyPress} className="flex-1" inputClassName='w-full' />
            <div className='flex flex-row flex-1 items-center'>
              <Button icon="save" title="Save" className="mx-2" onClick={onClickSave} variant='success' outline size="sm" />
              <Button icon="cancel" title="Cancel" onClick={onClickCancel} variant='danger' outline size="sm" />
            </div>
          </>
        }
        {!isEditing && <span className={styles.Title}>{chat.title}</span>}
      </div>
      <div className="mr-2 flex flex-row">
        {chat.internal_config && Object.keys(chat.internal_config).length > 0 && <Button icon='bug' onClick={onClickDebug} variant='link' outline />}
        <DropdownMenu icon={'ellipsisV'} items={items} variant='link' outline />
      </div>
    </div>
  )
}

const ChatView: React.FC = () => {
  const params = useParams();
  const chatId = params.chatId!;
  const chat = useChat(chatId);
  const currentUserId = useCurrentUserId();
  const dispatch = useAppDispatch();
  const isFetchingAll = useIsFetchingAll();
  const isFetchingOne = useIsFetchingOne();
  const [hasTriedFetchingChat, setHasTriedFetchingChat] = useState(false);
  const isFetching = isFetchingAll || isFetchingOne;
  const [formHeight, setFormHeight] = useState<number>(0);

  useEffect(() => {
    if (!chat && !isFetching && !hasTriedFetchingChat) {
      setHasTriedFetchingChat(true);
      dispatch(fetchChat(chatId));
    }
  }, [dispatch, chat, isFetching, chatId, setHasTriedFetchingChat, hasTriedFetchingChat])

  const onFormResized = React.useCallback((height: number) => {
    if (formHeight !== height) {
      setFormHeight(height);
    }
  }, [formHeight]);

  return (
    <div className={styles.Chat}>
      {!chat && isFetching && <Spinner />}
      {!chat && !isFetching && <div>Chat not found</div>}
      {chat &&
        (
          <>
            <ChatHeader chat={chat} />
            <MessageList chatId={chat.id} formHeight={formHeight} />
            <MessageForm chatId={chat.id} currentUserId={currentUserId} onResize={onFormResized} />
            <Outlet />
          </>
        )
      }
    </div>
  );
}

export default ChatView;
