import API from '@hubblai/hubbl-ui/lib/api.js';
import { uuid } from '@hubblai/hubbl-core/lib/string.js';
import { Dispatch } from 'redux';
import * as types from './types';
import { User } from '@hubblai/hubbl-ui/store/models.js';
import { Message } from '~/store/models';
import { MESSAGE_SOURCE, MESSAGE_STATUS } from '@hubblai/hubbl-core/models/Message.js';
import { APIStream } from '~/lib/stream';
import { nowUTS } from '@hubblai/hubbl-core/lib/clock.js';
import { GetStoreState } from '../types';
import { DEFAULT_PAGINATION } from './reducer';
import { TagEntityType } from '@hubblai/hubbl-core/lib/tags.js';

const createTempMessage = (user: User, content: string) => {
  const message = new Message();
  message.id = uuid();
  message.user_id = user.id;
  message.content = content;
  message.created_at = nowUTS();
  message.status = MESSAGE_STATUS.SUBMITTING;
  message.source = MESSAGE_SOURCE.USER;
  message.author = {
    id: user.id,
    icon: '',
    type: TagEntityType.USER,
    display_name: user.getDisplayName(),
  }
  return message
}

const getIsLastMessage = (getState: GetStoreState, chatId: string, messageId: string) => {
  const messages = getState().messages.messages[chatId]?.items || [];
  return messages[messages.length - 1].id === messageId;
}

export const subscribeToMessageContent = (dispatch: Dispatch, getState: GetStoreState, chatId: string, message: Message) => {
  const messageStream = APIStream.for(`/chats/${chatId}/messages/${message.id}/content`);
  messageStream.on('chunk', (content: string) => {
    dispatch({
      type: types.ON_MESSAGE_CONTENT_UPDATED,
      payload: {
        isLastMessage: getIsLastMessage(getState, chatId, message.id),
        message,
        chatId,
        content,
      }
    })
  });
  messageStream.connect();
}

export const onChatMessage = (dispatch: Dispatch, getState: GetStoreState, currentUserId: string, chatId: string, data: any): any => {
  const { method, payload } = data;
  const message = Message.fromDTO(payload);

  if (method === "POST") {
    if (message.isStreaming()) {
      subscribeToMessageContent(dispatch, getState, chatId, message);
    }
    // Ignoring own messages as assuming that these were sent from the current session, which would break in case of multiple simultanous device
    return {
      type: message.user_id === currentUserId ? types.ON_LOCAL_MESSAGE_RECEIVED : types.ON_MESSAGE_RECEIVED,
      payload: {
        isLastMessage: true,
        chatId,
        message
      }
    }
  } else if (method === "PATCH") {
    return {
      type: types.ON_MESSAGE_UPDATED,
      payload: {
        isLastMessage: getIsLastMessage(getState, chatId, message.id),
        chatId,
        message,
        upsert: true,
      }
    }
  }
}

export const createClientOnlyMessage = (chatId: string, content: string) => {
  const message = new Message();
  message.id = uuid();
  message.source = MESSAGE_SOURCE.CLIENT_ONLY;
  message.content = content;
  message.created_at = nowUTS();
  message.status = MESSAGE_STATUS.COMPLETED;

  return {
    type: types.ON_MESSAGE_RECEIVED,
    payload: {
      isLastMessage: false,
      chatId,
      message,
    }
  }
}

export const submitMessage = (chatId: string, content: string) => async (dispatch: Dispatch, getState: GetStoreState) => {
  const currentUser = getState().auth.user;
  if (!currentUser) {
    return null;
  }

  const tempMessage = createTempMessage(currentUser, content);

  dispatch({
    type: types.SUBMIT_MESSAGE_REQUEST_START,
    payload: {
      chatId,
      message: tempMessage,
    }
  });

  try {
    const { data } = await API.post(`/chats/${chatId}/messages`, { content });
    const message = Message.fromDTO(data);
    dispatch({
      type: types.SUBMIT_MESSAGE_REQUEST_SUCCESS,
      payload: {
        chatId,
        tempId: tempMessage.id,
        message
      }
    });
    return message;
  }
  catch (err) {
    console.error(err);
    dispatch({
      type: types.SUBMIT_MESSAGE_REQUEST_FAILURE,
      payload: {
        chatId,
        tempId: tempMessage.id,
      }
    });
  }
  return null;
};

export const fetchMessages = (chatId: string) => async (dispatch: Dispatch, getState: GetStoreState) => {
  dispatch({ type: types.FETCH_MESSAGES_REQUEST_START, payload: { chatId } });
  const { cursor, limit } = getState().messages.messages[chatId]?.pagination || {...DEFAULT_PAGINATION};

  try {
    const { data, pagination } = await API.get(`/chats/${chatId}/messages`, { limit, cursor });
    const messages = Message.fromDTOs(data);
    for (const message of messages) {
      if (message.isStreaming()) {
        subscribeToMessageContent(dispatch, getState, chatId, message);
      }
    }
    dispatch({
      type: types.FETCH_MESSAGES_REQUEST_SUCCESS,
      payload: {
        chatId,
        pagination,
        messages
      }
    });
  }
  catch (err) {
    console.error(err);
    dispatch({ type: types.FETCH_MESSAGES_REQUEST_FAILURE, payload: { err, chatId } });
  }
}

export const updateMessage = (chatId: string, messageId: string, payload: any) => async (dispatch: Dispatch) => {
  dispatch({
    type: types.UPDATE_MESSAGE_REQUEST.START,
    payload: {
      chatId,
      messageId,
      data: payload,
    }
  });
  try {
    const { data } = await API.patch(`/chats/${chatId}/messages/${messageId}`, payload);
    const message = Message.fromDTO(data);
    dispatch({
      type: types.UPDATE_MESSAGE_REQUEST.SUCCESS,
      payload: {
        chatId,
        message,
        upsert: false,
      }
    });
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_MESSAGE_REQUEST.FAILURE,
      payload: {
        chatId,
        messageId,
      }
    });
  }
}

export const cancelMessage = (chatId: string, messageId: string) => updateMessage(chatId, messageId, { status: MESSAGE_STATUS.CANCELLED });

export const clearNewMessage = (chatId: string) => ({
  type: types.CLEAR_NEW_MESSAGE,
  payload: {
    chatId,
  }
})

export const setNewMessage = (chatId: string, content: string) => ({
  type: types.SET_NEW_MESSAGE,
  payload: {
    chatId,
    content,
  }
});

export const appendNewMessage = (chatId: string, content: string) => ({
  type: types.APPEND_NEW_MESSAGE,
  payload: {
    chatId,
    content,
  }
});

export const setNewMessageAsLastCurrentUserMessage = (chatId: string) => (dispatch: Dispatch, getState: GetStoreState) => {
  const userId = getState().auth.user?.id;
  const messages = getState().messages.messages[chatId]?.items || [];
  if (messages.length) {
    const lastCurrentUserMessage = messages.reverse().find(message => message.user_id === userId);
    if (lastCurrentUserMessage && lastCurrentUserMessage.content.length) {
      dispatch(setNewMessage(chatId, lastCurrentUserMessage.content));
    }
  }
}
