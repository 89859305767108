import { BaseMessage } from "@hubblai/hubbl-core/models/Message.js";
import { ENTITY_TYPE } from "@hubblai/hubbl-core/models/Invitation.js";
import { BaseAgent } from "@hubblai/hubbl-core/models/Agent.js";
import { MESSAGE_SOURCE } from "@hubblai/hubbl-core/models/Message.js";
import { BaseOrganization } from "@hubblai/hubbl-core/models/Organization.js";
import { BaseModel, ModelFactory, User, Organization } from "@hubblai/hubbl-ui/store/models.js";

export class Agent extends BaseAgent {

  kits?: Kit[];
  organization?: BaseOrganization;

  static fromDTO(data: any): Agent {
    return ModelFactory.create<Agent>(Agent, {
      id: data.id,
      model_id: data.model_id,
      display_name: data.display_name,
      icon: data.icon,
      description: data.description,
      created_at: data.created_at,
      created_by: data.created_by,
      kits: data.kits ? Kit.fromDTOs(data.kits) : [],
      organization: data.organization ? Organization.fromDTO(data.organization) : undefined,
    });
  }

  static fromDTOs(data: any): Agent[] {
    return data.map((item: any) => Agent.fromDTO(item));
  }
}

export class Chat extends BaseModel {
  id = '';
  icon = '';
  title = '';
  created_at = 0;
  last_message_at = 0;
  last_message_by = '';
  last_message = '';
  last_seen_at = 0;

  internal_config: any;
  config: any;

  users: User[] = [];
  agents: Agent[] = [];

  isUnread() {
    return this.last_seen_at < this.last_message_at;
  }

  getLastMessageAuthor(): string | undefined {
    if (this.last_message_by) {
      if (this.users) {
        const user = this.users.find(user => user.id === this.last_message_by);
        if (user) {
          return user.getDisplayName();
        }
        const agent = this.agents.find(agent => agent.id === this.last_message_by);
        if (agent) {
          return agent.getDisplayName();
        }
      }
    }
    return undefined;
  }

  hasAgent(agentId: string) {
    return this.getAgent(agentId) !== undefined;
  }

  getAgent(agentId: string) {
    return this.agents?.find(agent => agent.id === agentId);
  }

  static fromDTOs(data: any): Chat[] {
    return data.map((item: any) => Chat.fromDTO(item));
  }

  static fromDTO(data: any): Chat {
    return ModelFactory.create<Chat>(Chat, {
      id: data.id,
      title: data.title,
      icon: data.icon,
      created_at: data.created_at,
      last_message_by: data.last_message_by,
      last_message_at: data.last_message_at,
      last_message: data.last_message,
      last_seen_at: data.last_seen_at,
      internal_config: data.internal_config,
      config: data.config,
      users: data.users ? User.fromDTOs(data.users) : [],
      agents: data.agents ? Agent.fromDTOs(data.agents) : [],
    });
  }

}

export class Message extends BaseMessage {
  tempId?: string;

  getGroupName(): string {
    if (this.source === MESSAGE_SOURCE.AGENT || this.source === MESSAGE_SOURCE.USER) {
      const author = this.getAuthor();
      return author?.display_name || '';
    }
    return '';
  }

  getGroupId(): string {
    const author = this.getAuthor();
    if (author) {
      return author.id;
    }
    return `${this.source}`;
  }

  static fromDTOs(data: any): Message[] {
    return data.map((item: any) => Message.fromDTO(item));
  }

  static fromDTO(data: any): Message {
    const message = ModelFactory.create<Message>(Message, {
      id: data.id,
      content: data.content,
      created_at: data.created_at,
      user_id: data.user_id,
      agent_id: data.agent_id,
      status: data.status,
      source: data.source,
      content_type: data.content_type,
      surface: data.surface,
    });

    if (data.author) {
      message.author = {
        id: data.author.id,
        type: data.author.type,
        display_name: data.author.display_name,
        icon: data.author.icon,
      }
    }
    if (data.metadata) {
      const tokens = data.metadata.tokens;
      if (tokens) {
        message.metadata = {
          tokens: {
            input: tokens.input || 0,
            input_cost: tokens.input_cost || 0,
            output: tokens.output || 0,
            output_cost: tokens.output_cost || 0,
          }
        }
      }
    }
    return message;
  }
}

export class Invitation extends BaseModel {
  entityId = '';
  entityTitle = '';
  entityType: ENTITY_TYPE = ENTITY_TYPE.Chat;
  author = '';

  static fromDTO(data: any): Invitation {
    return ModelFactory.create<Invitation>(Invitation, {
      entityId: data.entity_id,
      entityTitle: data.entity_title,
      entityType: data.entity_type || ENTITY_TYPE.Chat,
      author: data.author,
    });
  }
}

export class Kit extends BaseModel {

  id?: string;
  title?: string;
  description?: string;
  icon?: string;

  static fromDTO(data: any): Kit {
    return ModelFactory.create<Kit>(Kit, {
      id: data.id,
      title: data.title,
      description: data.description,
      icon: data.icon,
    });
  }

  static fromDTOs(data: any): Kit[] {
    return data.map((item: any) => Kit.fromDTO(item));
  }
}
