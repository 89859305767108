import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { MentionsInput, Mention, OnChangeHandlerFunc } from 'react-mentions';

import { createUserTag, createAgentTag, TagEntityType, createTagId } from '@hubblai/hubbl-core/lib/tags.js';
import { Button, DropdownMenu, DropdownMenuItem } from '@hubblai/hubbl-ui/components/index.js';

import { useIsSubmitting, useNewMessage } from '~/store/messages/hooks';
import { submitMessage, clearNewMessage, setNewMessage, appendNewMessage, setNewMessageAsLastCurrentUserMessage } from '~/store/messages/actions';
import { useAppDispatch } from '@hubblai/hubbl-ui/store/index.js';
import styles from "./MessageForm.module.css";
import { useChatAgents, useChatOtherUsers } from '~/store/chats/hooks';
import { User } from '@hubblai/hubbl-ui/store/models.js';
import { Agent } from '~/store/models';

type Props = {
  chatId: string,
  currentUserId: string,
  onResize: (height: number) => void,
}

type MentionItem = {
  id: string,
  display: string,
}

const getDisplayMention = (_id: string, display: string) => `@${display}`;

const MessageForm: React.FC<Props> = ({ chatId, currentUserId, onResize }) => {
  const rootRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const isSubmitting = useIsSubmitting();
  const dispatch = useAppDispatch();
  const message = useNewMessage(chatId);
  const [isTagging, setIsTagging] = useState(false);
  const agents = useChatAgents(chatId);
  const users = useChatOtherUsers(chatId, currentUserId);
  const rootHeightRef = useRef<number>(0);

  useEffect(() => {
    if (inputRef?.current && message.content && message.content.length > 0) {
      inputRef.current.focus();
    }
    if (rootRef?.current) {
      if (rootHeightRef.current != rootRef.current.offsetHeight) {
        rootHeightRef.current = rootRef.current.offsetHeight;
        onResize(rootHeightRef.current);
      }
    }
  }, [message, onResize])

  useEffect(() => {
    if (rootRef.current) {
      if (rootHeightRef.current != rootRef.current.offsetHeight) {
        rootHeightRef.current = rootRef.current.offsetHeight;
        onResize(rootHeightRef.current);
      }
    }
  }, [rootRef, onResize]);

  const onClickSendMessage = useCallback(() => {
    if (!isSubmitting && message && chatId) {
      const pureContent = message.content.trim();
      if (pureContent.length > 0) {
        dispatch(submitMessage(chatId, pureContent));
        dispatch(clearNewMessage(chatId));
      }
    }
  }, [message, isSubmitting, dispatch, chatId]);

  const onMessageChanged: OnChangeHandlerFunc = (e: any) => {
    dispatch(setNewMessage(chatId, e.target.value));
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onClickSendMessage();
    }
  }

  const onKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "ArrowUp" && message.content.length === 0) {
      dispatch(setNewMessageAsLastCurrentUserMessage(chatId));
    }
  }

  const onTagMenuHide = () => {
    setIsTagging(false);
  }

  const onClickTagMenu = () => {
    setIsTagging(!isTagging);
  }

  const onTag = (tag: string) => {
    dispatch(appendNewMessage(chatId, tag + ' '));
  }

  const onTagAgent = (agent: Agent) => {
    onTag(createAgentTag(agent));
  };

  const onTagUser = (user: User) => {
    onTag(createUserTag(user));
  };

  const groups: DropdownMenuItem[] = [];

  if (agents.length) {
    groups.push({
      label: 'AI',
      items: []
    });
  }
  if (users.length) {
    groups.push({
      label: 'Users',
      items: []
    });
  }

  const canTag = groups.length > 0;

  const mentions: MentionItem[] = [];

  agents.forEach(agent => {
    groups[0].items!.push({
      label: agent.getDisplayName(),
      onClick: () => onTagAgent(agent),
    });
    mentions.push({
      id: createTagId(TagEntityType.AGENT, agent.id),
      display: agent.getDisplayName()
    });
  })
  users.forEach(user => {
    mentions.push({
      id: createTagId(TagEntityType.USER, user.id),
      display: user.getDisplayName()
    });
    groups[groups.length - 1]!.items!.push({
      label: user.getDisplayName(),
      onClick: () => onTagUser(user),
    });
  })

  const onClickRoot = (e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLElement).nodeName === "DIV" && inputRef?.current) {
      inputRef?.current.focus();
    }
  };

  return (
    <div className={clsx(styles.MessageForm)} onClick={onClickRoot} ref={rootRef}>
      {canTag && <DropdownMenu icon='at' buttonClassName={"border-2"} variant={isTagging ? 'primary' : 'link'} items={groups} onToggle={onClickTagMenu} onHide={onTagMenuHide} outline rounded />}
      <MentionsInput
        inputRef={inputRef}
        value={message.content}
        onChange={onMessageChanged}
        onKeyPress={onKeyPress}
        onKeyUp={onKeyUp}
        placeholder="Type your message..."
        className={"mentions"}
        forceSuggestionsAboveCursor>
        <Mention
          className={"mentions__mention"}
          trigger="@"
          data={mentions}
          markup={"@[__display__](__id__)"}
          displayTransform={getDisplayMention}
          appendSpaceOnAdd
        />
      </MentionsInput >

      <Button onClick={onClickSendMessage} title={"Send"} isLoading={isSubmitting} className={styles.Button} rounded />
    </div >
  );
}

export default MessageForm;
