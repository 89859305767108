import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Icon, Input, Modal, ButtonItem } from '@hubblai/hubbl-ui/components/index.js';
import { useChat } from "~/store/chats/hooks";
import { inviteAgent, uninviteAgent } from "~/store/chats/actions";
import { useAppDispatch } from "@hubblai/hubbl-ui/store/index.js";
import { fetchAgents } from "~/store/agents/actions";
import { addToast } from "@hubblai/hubbl-ui/store/notifications/actions.js";
import { useAgents, useIsFetching } from "~/store/agents/hooks";
import { Agent } from "~/store/models";
import { useDebounce } from "@hubblai/hubbl-ui/hooks/index.js";
import { createClientOnlyMessage } from "~/store/messages/actions";

type AgentListItemProps = {
  agent: Agent,
  onClickAdd: (agent: Agent) => void,
  onClickRemove: (agent: Agent) => void,
  isInChat: boolean,
}

const AgentListItem: React.FC<AgentListItemProps> = ({ agent, onClickAdd, onClickRemove, isInChat }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onClick = () => setIsExpanded(currentValue => !currentValue);

  return (
    <div className="rounded-sm border-gray-300 border mb-3 p-2">
      <div className="flex flex-row mt-1">
        <div className="flex items-center flex-col">
          <img src={agent.icon} className="w-20 h-20 rounded" />
        </div>
        <div className="ml-3 flex-1">
          <div className="text-lg font-semibold mb-1 flex  justify-between">
            <span>{agent.getDisplayName()}</span>
          </div>
          <div className="text-sm mb-2 flex flex-row items-center">By hubbl <Icon name='verified' className="ml-1 text-primary" /></div>
          <div className="text-sm">{agent.description || 'No description'}</div>
        </div>
        <div>
          <div className="flex items-center">
            {!isInChat && <Button onClick={() => onClickAdd(agent)} title="Add to Chat"  size="sm" className="px-3 py-1 ml-1" rounded icon="plus" />}
            {isInChat && <Button onClick={() => onClickRemove(agent)} title="Remove from Chat"  size="sm" className="px-3 py-1 ml-1" rounded icon="minus" variant="danger" />}
            {agent.kits && agent.kits.length > 0 && <Button onClick={onClick}  size="icon" className="px-0 py-0 ml-2" rounded icon={isExpanded ? 'arrow-up' : 'arrow-down'} />}
          </div>
        </div>
      </div>
      {isExpanded &&
        <div className="mt-3 ml-24">
          <div className="mb-3">Capabilities:</div>
          {agent.kits?.map(kit =>
            <div className="mb-1" key={kit.id}>
              <div className="text-sm font-semibold">{kit.title}</div>
              <div className="text-sm">{kit.description}</div>
            </div>
          )}
        </div>
      }
    </div>
  )
}

const InviteAIModal: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const chatId = params.chatId!;
  const chat = useChat(chatId)!;
  const agents = useAgents();
  const isFetching = useIsFetching();
  const [query, setQuery] = useState<string>("");
  const debouncedQuery = useDebounce(query, 500);

  const [isOpened, setIsOpened] = useState(true);

  const buttons: ButtonItem[] = [{
    title: "Close",
    action: 'close',
    variant: 'info',
    outline: true,
  }];

  useEffect(() => {
    dispatch(fetchAgents(debouncedQuery));
  }, [dispatch, debouncedQuery]);

  const onSearchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  }

  const onClickAddAgent = useCallback((agent: Agent) => {
    setIsOpened(false);
    dispatch(inviteAgent(chatId, agent.id));
    dispatch(addToast(`${agent.getDisplayName()} will join your conversation now!`));
  }, [setIsOpened, dispatch, chatId]);

  const onClickRemoveAgent = useCallback((agent: Agent) => {
    dispatch(uninviteAgent(chatId, agent.id));
    dispatch(addToast(`${agent.getDisplayName()} was removed from the chat!`));
    dispatch(createClientOnlyMessage(chatId, `${agent.getDisplayName()} was kicked out...`));
  }, [dispatch, chatId]);

  return (
    <Modal buttons={buttons} title={`Invite Agent to ${chat.title}`} navigate={navigate} isOpened={isOpened} size='almost-max'>
      <Input className="flex-1 mr-2" value={query} onChange={onSearchChanged} placeholder="Search for agents..." isLoading={isFetching} />
      {!isFetching && agents.length === 0 && <div>No agents found</div>}
      {agents.map(agent => <AgentListItem agent={agent} key={agent.id} onClickAdd={onClickAddAgent} onClickRemove={onClickRemoveAgent} isInChat={chat.hasAgent(agent.id)} />)}
    </Modal >
  )
}

export default InviteAIModal;
