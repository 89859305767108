import { useCallback, useEffect, useState } from "react";
import API from "@hubblai/hubbl-ui/lib/api.js";
import { useNavigate } from "react-router-dom";
import { Invitation } from "~/store/models";
import { useAppDispatch } from "@hubblai/hubbl-ui/store/index.js";
import { addToast } from "@hubblai/hubbl-ui/store/notifications/actions.js";
import { Spinner } from "@hubblai/hubbl-ui/components/index.js";

type Props = {
  token: string,
}

const InvitationView: React.FC<Props> = ({ token }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [invitation, setInvitation] = useState<Invitation | null>(null);

  const onInvitationFetchFailure = useCallback(async () => {
    dispatch(addToast('Error', 'There was some error with your invitation. Either it has expired or it has been accepted already.'));
    navigate('/');
  }, [navigate, dispatch]);

  const fetchInvitation = useCallback(async (token: string) => {
    setIsFetching(true);
    try {
      const { data } = await API.get(`/invitations/${token}`);
      if (data) {
        const invitation = Invitation.fromDTO(data);
        setInvitation(invitation)
      } else {
        onInvitationFetchFailure();
      }
    }
    catch {
      onInvitationFetchFailure();
    }
    setIsFetching(false);
  }, [setIsFetching, setInvitation, onInvitationFetchFailure]);

  useEffect(() => {
    fetchInvitation(token);
  }, [token, fetchInvitation])

  return (
    <div>
      {isFetching && <Spinner />}
      {invitation &&
        <>
          {invitation.author} has invited you to join {invitation.entityTitle}!
        </>
      }
    </div>
  )
}

export default InvitationView;
