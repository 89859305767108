import update from 'immutability-helper';
import { RESET, Action } from '@hubblai/hubbl-ui/store/types.js';
import * as globalTypes from '~/store/types';
import * as types from './types';
import { Message } from '~/store/models';
import { MESSAGE_STATUS } from '@hubblai/hubbl-core/models/Message.js';
import config from '~/config';

export type MessageContent = {
  content: string,
}

export const DEFAULT_PAGINATION: globalTypes.Pagination = {
  limit: config.FETCH_MESSAGES_DEFAULT_LIMIT,
  hasNextPage: false,
  cursor: undefined,
}

export type MessageState = {
  pagination: globalTypes.Pagination,
  isFetching: boolean,
  hasMountFetched?: boolean,
  items: Message[]
}

export type MessageMap = {
  [chatId: string]: MessageState,
}

export type MessagesState = {
  isSubmitting: boolean,
  messages: MessageMap,
  newMessages: {
    [chatId: string]: MessageContent,
  },
}

const INITIAL_STATE: MessagesState = {
  isSubmitting: false,
  messages: {},
  newMessages: {},
}

const getMessageIndexById = (state: MessagesState, chatId: string, id: string): number => {
  if (state.messages[chatId]) {
    return state.messages[chatId].items.findIndex(message => message.id === id);
  }
  return -1;
}

const updateNewMessage = (state: MessagesState, chatId: string, content: string, isAppend = false) => {
  const existingMessage = state.newMessages[chatId];
  if (existingMessage) {
    const newContent = isAppend ? existingMessage.content + content : content;
    return update(state, {
      newMessages: {
        [chatId]: {
          content: { $set: newContent }
        },
      }
    });
  }
  return update(state, {
    newMessages: {
      [chatId]: { $set: { content } }
    },
  });
}

const createMessageState = (state: MessagesState, chatId: string, data: MessageState) => {
  return update(state, {
    messages: {
      [chatId]: { $set: data }
    },
  });
}

const reducer = (state = { ...INITIAL_STATE }, { type, payload }: Action) => {
  switch (type) {
    case types.FETCH_MESSAGES_REQUEST_START: {
      const { chatId } = payload;
      if (state.messages[chatId]) {
        return update(state, {
          messages: {
            [chatId]: {
              isFetching: { $set: true },
            }
          },
        });
      }
      return createMessageState(state, chatId, {
        hasMountFetched: false,
        items: [],
        pagination: { ...DEFAULT_PAGINATION },
        isFetching: true
      });
    }
    case types.FETCH_MESSAGES_REQUEST_SUCCESS: {
      const { messages, pagination, chatId } = payload;
      if (state.messages[chatId]) {
        const currentMessageIds = new Set(state.messages[chatId].items.flatMap((msg: Message) => msg.id));
        const uniqueMessages = messages.filter((msg: Message) => !currentMessageIds.has(msg.id));

        return update(state, {
          messages: {
            [chatId]: {
              items: { $unshift: uniqueMessages },
              pagination: { $set: pagination },
              isFetching: { $set: false },
              hasMountFetched: { $set: true },
            }
          }
        });
      }
      return createMessageState(state, chatId, {
        pagination: { ...DEFAULT_PAGINATION },
        hasMountFetched: true,
        items: messages,
        isFetching: false,
      });
    }
    case types.FETCH_MESSAGES_REQUEST_FAILURE: {
      const { chatId } =  payload;
      if (state.messages[chatId]) {
        return update(state, {
          messages: {
            [chatId]: {
              isFetching: { $set: false },
              hasMountFetched: { $set: true },
            }
          }
        });
      }

      return createMessageState(state, chatId, {
        items: [],
        hasMountFetched: true,
        pagination: { ...DEFAULT_PAGINATION },
        isFetching: false,
      });
    }

    case types.SUBMIT_MESSAGE_REQUEST_START: {
      const message = payload.message as Message;
      const chatId = payload.chatId as string;

      if (state.messages[chatId]) {
        return update(state, {
          messages: {
            [chatId]: {
              items: { $push: [message] }
            }
          }
        });
      }

      return createMessageState(state, chatId, {
        pagination: { ...DEFAULT_PAGINATION },
        items: [message],
        isFetching: false,
      });
    }

    case types.SUBMIT_MESSAGE_REQUEST_SUCCESS: {
      const { message, tempId, chatId } = payload;
      const messageIndex = getMessageIndexById(state, chatId, tempId);
      if (messageIndex > -1) {
        message.status = MESSAGE_STATUS.COMPLETED;
        return update(state, {
          messages: {
            [chatId]: {
              items: {
                [messageIndex]: { $set: message },
              }
            }
          },
          isSubmitting: { $set: false },
        })
      }
      return state;
    }

    case types.SUBMIT_MESSAGE_REQUEST_FAILURE: {
      const { tempId, chatId } = payload;
      const messageIndex = getMessageIndexById(state, chatId, tempId);
      if (messageIndex > -1) {
        return update(state, {
          messages: {
            [chatId]: {
              items: {
                [messageIndex]: {
                  status: { $set: MESSAGE_STATUS.FAILED_TO_SUBMIT },
                },
              }
            }
          },
          isSubmitting: { $set: false },
        });
      }
      return state;
    }

    case types.UPDATE_MESSAGE_REQUEST.SUCCESS:
    case types.ON_MESSAGE_UPDATED: {
      const { chatId, message, upsert } = payload;
      const messageIndex = getMessageIndexById(state, chatId, message.id);
      if (messageIndex > -1) {
        return update(state, {
          messages: {
            [chatId]: {
              items: {
                [messageIndex]: { $set: message },
              }
            }
          }
        });
      }

      if (state.messages[chatId] && upsert) {
        return update(state, {
          messages: {
            [chatId]: {
              items: { $push: [message] },
            }
          }
        })
      }

      // First message in the chat
      if (upsert) {
        return createMessageState(state, chatId, {
          items: [message],
          pagination: {...DEFAULT_PAGINATION},
          isFetching: false,
        });
      }
      return state;
    }
    case types.ON_MESSAGE_RECEIVED: {
      const { chatId, message } = payload;
      if (!state.messages[chatId]) {
        // First message in the chat
        return createMessageState(state, chatId, {
          items: [message],
          pagination: {...DEFAULT_PAGINATION},
          isFetching: false,
        });
      }

      const messageIndex = getMessageIndexById(state, chatId, message.id);
      // Message does not exist yet
      if (messageIndex === -1) {
        return update(state, {
          messages: {
            [chatId]: {
              items: { $push: [message] },
            }
          }
        });
      }
      return state;
    }
    case types.ON_MESSAGE_CONTENT_UPDATED: {
      const { message, content, chatId } = payload;
      const messageIndex = getMessageIndexById(state, chatId, message.id);
      if (messageIndex > -1) {
        const currentContent = state.messages[chatId].items[messageIndex].content || '';
        return update(state, {
          messages: {
            [chatId]: {
              items: {
                [messageIndex]: {
                  content: { $set: currentContent + content }
                }
              }
            }
          }
        })
      }
      return state;
    }

    case types.SET_NEW_MESSAGE: {
      const { chatId, content } = payload;
      return updateNewMessage(state, chatId, content);
    }
    case types.APPEND_NEW_MESSAGE: {
      const { chatId, content } = payload;
      return updateNewMessage(state, chatId, content, true);
    }
    case types.CLEAR_NEW_MESSAGE: {
      const { chatId } = payload;
      return updateNewMessage(state, chatId, '');
    }

    case RESET:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};


export default reducer;
