import API from '@hubblai/hubbl-ui/lib/api.js';
import { Dispatch } from 'redux';
import * as types from './types';
import { User } from '@hubblai/hubbl-ui/store/models.js';
import { Agent, Chat } from '../models';
import { onChatMessage } from '../messages/actions';
import { nowUTS } from '@hubblai/hubbl-core/lib/clock.js';
import { GetStoreState } from '../types';

export const subscribeToChat = (dispatch: Dispatch, getState: any, chatId: string, userId: string)  => {
  API.subscribe(`/chats/${chatId}`, '/debug', data => {
    // TODO:P2 create debug modal
    // TODO:P2 only in case I have access to debug and the chat is enabled for it
    console.log(data.payload);
  });
  API.subscribe(`/chats/${chatId}`, '/messages', data => dispatch(onChatMessage(dispatch, getState, userId, chatId, data)));
  API.subscribe(`/chats/${chatId}`, '/users', data => {
    const { method, payload } = data;
    if (method === "POST") {
      dispatch({
        type: types.ON_CHAT_USER.ADDED,
        payload: {
          user: User.fromDTO(payload),
          chatId,
        }
      });
    } else if (method === "DELETE") {
      dispatch({
        type: types.ON_CHAT_USER.REMOVED,
        payload: {
          ...payload,
          chatId,
        }
      });
    }
  });
  API.subscribe(`/chats/${chatId}`, '/agents', data => {
    const { method, payload } = data;
    if (method === "POST") {
      dispatch({
        type: types.ON_CHAT_AGENT.ADDED,
        payload: {
          agent: Agent.fromDTO(payload),
          chatId,
        }
      });
    } else if (method === "DELETE") {
      dispatch({
        type: types.ON_CHAT_AGENT.REMOVED,
        payload: {
          ...payload,
          chatId,
        }
      });
    }
  });
}

export const createChat = (agentId?: string) => async (dispatch: Dispatch, getState: GetStoreState) => {
  const state = getState();
  const userId = state.auth.user?.id;
  if (!userId) {
    return null;
  }
  dispatch({ type: types.CREATE_CHAT_REQUEST_START });

  try {
    const { data } = await API.post('/chats', { agent_id: agentId });
    const chat = Chat.fromDTO(data);
    dispatch({ type: types.CREATE_CHAT_REQUEST_SUCCESS, payload: { chat } });
    subscribeToChat(dispatch, getState, chat.id, userId);
    return chat;
  }
  catch (err) {
    dispatch({ type: types.CREATE_CHAT_REQUEST_FAILURE });
  }
  return null;
};

export const leaveChat = (chatId: string) => async (dispatch: Dispatch, getState: any) => {
  const userId = getState().auth.user.id;
  dispatch({ type: types.LEAVE_CHAT_REQUEST_START, payload: { chatId } });

  try {
    await API.delete(`/chats/${chatId}/users/${userId}`);
    API.unsubscribe(`/chats/${chatId}`);
    dispatch({ type: types.LEAVE_CHAT_REQUEST_SUCCESS, payload: { chatId } });
  }
  catch (err) {
    dispatch({ type: types.LEAVE_CHAT_REQUEST_FAILURE, payload: { chatId } });
  }
}

export const removeUserFromChat = (chatId: string, userId: string) => async (dispatch: Dispatch) => {
  dispatch({ type: types.LEAVE_CHAT_REQUEST_START, payload: { chatId, userId } });

  try {
    await API.delete(`/chats/${chatId}/users/${userId}`);
    dispatch({ type: types.LEAVE_CHAT_REQUEST_SUCCESS, payload: { chatId, userId } });
  }
  catch (err) {
    dispatch({ type: types.LEAVE_CHAT_REQUEST_FAILURE, payload: { chatId, userId } });
  }
}

export const fetchChats = () => async (dispatch: Dispatch, getState: GetStoreState) => {
  const state = getState();
  const userId = state.auth.user?.id;
  if (!userId) {
    return null;
  }

  const { cursor, limit } = state.chats.pagination;
  dispatch({ type: types.FETCH_CHATS_REQUEST_START });

  try {
    const { data, pagination } = await API.get('/chats', {
      cursor,
      limit,
    });
    const chats = Chat.fromDTOs(data);
    dispatch({ type: types.FETCH_CHATS_REQUEST_SUCCESS, payload: { pagination, chats } });
    for (const chat of chats) {
      subscribeToChat(dispatch, getState, chat.id, userId);
    }

  }
  catch (err) {
    console.error(err);
    dispatch({ type: types.FETCH_CHATS_REQUEST_FAILURE });
  }
}

export const fetchChat = (chatId: string) => async (dispatch: Dispatch, getState: GetStoreState) => {
  const userId = getState().auth.user?.id;
  if (!userId) {
    return null;
  }

  dispatch({ type: types.FETCH_CHAT_REQUEST_START });

  try {
    const { data } = await API.get(`/chats/${chatId}`);
    const chat = Chat.fromDTO(data);
    dispatch({ type: types.FETCH_CHAT_REQUEST_SUCCESS, payload: { chat } });
    subscribeToChat(dispatch, getState, chat.id, userId);
  }
  catch (err) {
    dispatch({ type: types.FETCH_CHAT_REQUEST_FAILURE });
  }
}

export const inviteUsers = (chatId: string, emails: string[]) => async () => {
  try {
    await API.post(`/chats/${chatId}/users`, { emails });
  }
  catch (err) {
    // TODO:P2 toast error
  }
}

export const markAsRead = (chatId: string) => async (dispatch: Dispatch, getState: GetStoreState) => {
  const currentTime = nowUTS();
  const payload = { chatId, last_seen_at: currentTime };
  const userId = getState().auth.user?.id;
  dispatch({ type: types.MARK_AS_READ_START, payload });
  try {
    await API.patch(`/chats/${chatId}/users/${userId}`, {
      last_seen_at: currentTime,
    });
    dispatch({ type: types.MARK_AS_READ_SUCCESS, payload });
  }
  catch (err) {
    dispatch({ type: types.MARK_AS_READ_FAILURE, payload });
  }
}

export const updateChat = (chatId: string, data: any) => async (dispatch: Dispatch) => {
  const payload = { chatId, data };
  dispatch({ type: types.EDIT_CHAT_START, payload });
  try {
    await API.patch(`/chats/${chatId}`, data);
    dispatch({ type: types.EDIT_CHAT_SUCCESS, payload });
  }
  catch (err) {
    dispatch({ type: types.EDIT_CHAT_FAILURE, payload });
    throw err;
  }
}

export const inviteAgent = (chatId: string, agentId: string) => async (dispatch: Dispatch) => {
  dispatch({ type: types.INVITE_AGENT_REQUEST_START });

  try {
    const { data } = await API.post(`/chats/${chatId}/agents`, { id: agentId });
    const agent = Agent.fromDTO(data);
    dispatch({ type: types.INVITE_AGENT_REQUEST_SUCCESS, payload: { chatId, agent } });
  }
  catch (err) {
    dispatch({ type: types.INVITE_AGENT_REQUEST_FAILURE });
    throw err;
  }
}

export const uninviteAgent = (chatId: string, agentId: string) => async (dispatch: Dispatch) => {
  dispatch({ type: types.UNINVITE_AGENT_REQUEST.START });

  try {
    const { data } = await API.delete(`/chats/${chatId}/agents/${agentId}`);
    const agent = Agent.fromDTO(data);
    dispatch({ type: types.UNINVITE_AGENT_REQUEST.SUCCESS, payload: { chatId, agent } });
  }
  catch (err) {
    dispatch({ type: types.UNINVITE_AGENT_REQUEST.FAILURE });
  }
}
