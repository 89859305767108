import { useSelector } from 'react-redux';
import { memoize } from 'proxy-memoize';
import { AgentsState } from './reducer';
import { Agent } from '~/store/models';

type AgentsStoreType = {
  agents: AgentsState,
}

const selectAgents = (state: AgentsStoreType) => state.agents.agents;

export const useAgents = () => useSelector<AgentsStoreType, Agent[]>(memoize(selectAgents));

export const useIsFetching = () => useSelector<AgentsStoreType, boolean>(state => state.agents.isFetching);
