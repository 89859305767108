import update from 'immutability-helper';
import { RESET, Action } from '@hubblai/hubbl-ui/store/types.js';
import * as types from './types';
import { Agent } from '~/store/models';

export type AgentsState = {
  isFetching: boolean,
  query?: string,
  agents: Agent[],
}

const INITIAL_STATE: AgentsState = {
  isFetching: false,
  query: undefined,
  agents: [],
}

const reducer = (state = { ...INITIAL_STATE }, { type, payload }: Action) => {
  switch (type) {
    case types.FETCH_AGENTS_REQUEST.START: {
      const { query } = payload;
      return update(state, {
        query: { $set: query },
        isFetching: { $set: true },
      });
    }
    case types.FETCH_AGENTS_REQUEST.SUCCESS: {
      const { query, data } = payload;
      if (query === state.query) {
        return update(state, {
          isFetching: {$set: false},
          agents: {$set: data},
        });
      }
      return state;
    }
    case types.FETCH_AGENTS_REQUEST.FAILURE: {
      const { query } = payload;
      if (query === state.query) {
        return update(state, {
          isFetching: { $set: false },
        });
      }
      return state;
    }

    case RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};


export default reducer;
