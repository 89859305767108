import clsx from 'clsx';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import styles from "./Message.module.css";

import { Button, Tag } from '@hubblai/hubbl-ui/components/index.js';

import { Message } from '~/store/models';
import { MESSAGE_CONTENT_TYPE, MESSAGE_SOURCE, MESSAGE_STATUS, MESSAGE_SURFACE } from '@hubblai/hubbl-core/models/Message.js';
import { MessageTag, parseTagsFromString, renderTags, getDisplayTag, findIncompleteTag, getMarkdownTag } from '@hubblai/hubbl-core/lib/tags.js';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { replaceWith } from '@hubblai/hubbl-core/lib/string.js';
import { prettify } from '@hubblai/hubbl-core/lib/math.js';
import { Link } from 'react-router-dom';
import { usePreferences } from '@hubblai/hubbl-ui/store/auth/hooks.js';

type MessageProps = {
  message: Message,
  currentUserId: string,
}

type MessageFunctionCallProps = {
  message: Message,
}

type MessageContentProps = {
  message: Message,
}

type MessageCostProps = {
  message: Message,
}

type TagLinkProps = {
  tag: MessageTag,
}

const TagLink: React.FC<TagLinkProps> = ({ tag }) => <Link className={styles.tagLink} to="/">{getDisplayTag(tag)}</Link>;

const MessageCost: React.FC<MessageCostProps> = ({ message }) => {
  const tokens = message.metadata.tokens;
  if (tokens.input > 0 || tokens.output > 0) {
    return (
      <div className='text-sm text-right mt-1 italic opacity-50'>
        {tokens.input + tokens.output} Tokens | ${prettify(tokens.input_cost + tokens.output_cost)}
      </div>
    )
  }
  return <></>;
}

const MessageFunctionCall: React.FC<MessageFunctionCallProps> = ({ message }) => {
  const content = message.getContent<any>();
  return (
    <div className={clsx(styles.content, styles.contentText, styles.contentFunctionCall)}>
      <img src={content.icon} />
      <span>Executing {content.title}: {content.functionName}...</span>
    </div>
  )
}

const MessageText: React.FC<MessageContentProps> = ({ message }) => {
  const preferences = usePreferences();
  const wasCancelled = message.isCancelled();
  const text = message.getContent<string>();
  const isByAgent = message.isByAgent();
  const tags = parseTagsFromString(text);
  const content: React.ReactNode[] = renderTags(text, tags, (tag: MessageTag, i: number) => {
    if (isByAgent) {
      return getMarkdownTag(tag);
    }
    return <TagLink tag={tag} key={i} />;
  });

  if (isByAgent) {
    const lastIndex = content.length - 1;
    const lastContentChunk = content[lastIndex];
    if (lastContentChunk && typeof lastContentChunk === 'string') {
      const match = findIncompleteTag(lastContentChunk);
      if (match) {
        content[lastIndex] = replaceWith(lastContentChunk, match.markdownValue, match.index, match.index + match.value.length);
      }
    }
  }

  return (
    <div className={clsx(styles.content, styles.contentText)}>
      {isByAgent &&
        <Markdown
          className={styles.markdown}
          remarkPlugins={[remarkGfm]}
          children={content.join('').toString()}
          components={{
            pre(pre) {
              const codeChunk = (pre as any).node?.children[0]?.children[0]?.value as string;
              const className = (pre as any).node?.children[0]?.properties?.className;
              if (className) {
                const language = className[0].replace(/language-/g, "") as string;
                return (
                  <div className={styles.code}>
                    <div className={styles.codeHeader}>
                      <Tag title={language} className={styles.codeTag} />
                      <CopyToClipboard text={codeChunk}>
                        <Button icon="copy" size="icon" tooltip='Copy' className={styles.codeButton} />
                      </CopyToClipboard>
                    </div>
                    <pre {...pre}></pre>
                  </div>
                );
              }
              return <pre {...pre}></pre>;
            },
            code(props) {
              const { children, className, node, ...rest } = props
              const match = /language-(\w+)/.exec(className || '')
              return match ? (
                <SyntaxHighlighter
                  // {...rest}
                  PreTag="div"
                  className={styles.highlighter}
                  children={String(children).replace(/\n$/, '')}
                  language={match[1]}
                  style={oneDark}
                  showLineNumbers
                />
              ) : (
                <code {...rest} className={className}>
                  {children}
                </code>
              )
            }
          }}
        />}
      {!isByAgent && content}
      {wasCancelled && <span className='italic text-sm opacity-50'>(interrupted)</span>}
      {preferences.displayMessageCost && message.metadata?.tokens && <MessageCost message={message} />}
    </div>
  )
}

const MessageIFrame: React.FC<MessageContentProps> = ({ message }) => {
  const content = message.getContent<any>();
  return (
    <div className={clsx(styles.content, styles.contentImage)}>
      <iframe width={content.width} height={content.height} src={content.url} title={content.title} />
    </div>
  )
}

const MessageImage: React.FC<MessageContentProps> = ({ message }) => {
  const content = message.getContent<any>();
  return (
    <div className={clsx(styles.content, styles.contentImage)}>
      <img width={content.width} height={content.height} src={content.url} title={content.title} />
    </div>
  )
}

const MessageComponent: React.FC<MessageProps> = ({ message, currentUserId }) => {
  const text = message.getContent<any>();
  const isEmpty = !text || text.length === 0;
  const isByAgent = message.isByAgent();
  const isByCurrentUser = message.isByUser(currentUserId);
  const isSubmitting = message.status === MESSAGE_STATUS.SUBMITTING;
  const isTyping = message.status === MESSAGE_STATUS.TYPING;
  const isValidContent = message.content_type === MESSAGE_CONTENT_TYPE.JSON ? typeof text === 'object' : typeof text === 'string';
  const hasError = message.isError() || !isValidContent;

  return (
    <div className={clsx(styles.Message, {
      [styles.client]: message.source === MESSAGE_SOURCE.CLIENT_ONLY || message.source === MESSAGE_SOURCE.SYSTEM,
      [styles.agent]: isByAgent,
      [styles.current]: isByCurrentUser,
      [styles.submitting]: isSubmitting,
      [styles.error]: hasError,
      [styles.typing]: isTyping,
    })}>
      {!isEmpty &&
        <>
          {(message.surface === MESSAGE_SURFACE.TEXT || typeof text === 'string') && <MessageText message={message} />}
          {message.surface === MESSAGE_SURFACE.IFRAME && isValidContent && <MessageIFrame message={message} />}
          {message.surface === MESSAGE_SURFACE.IMAGE && isValidContent && <MessageImage message={message} />}
          {message.surface === MESSAGE_SURFACE.FUNCTION_CALL && isValidContent && <MessageFunctionCall message={message} />}
        </>
      }
    </div >
  )
}

export default MessageComponent;
