import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useChat } from "~/store/chats/hooks";
import { useAppDispatch } from "@hubblai/hubbl-ui/store/index.js";
import { updateChat } from "~/store/chats/actions";
import { getDisplayError } from "@hubblai/hubbl-ui/lib/api.js";
import { addToast } from "@hubblai/hubbl-ui/store/notifications/actions.js";
import { Modal, ButtonItem, Checkbox, Input } from "@hubblai/hubbl-ui/components/index.js";

const DebugModal: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const chatId = params.chatId!;
  const chat = useChat(chatId);
  const [isSaving, setIsSaving] = useState(false);
  const [canMA, setCanMA] = useState(false);
  const [debug, setDebug] = useState(false);
  const [maxMADepth, setMaxMADepth] = useState(0);
  const [isOpened, setIsOpened] = useState(true);
  const [alwaysInvokeAllByUser, setAlwaysInvokeAllByUser] = useState(false);
  const [alwaysInvokeAllByAgent, setAlwaysInvokeAllByAgent] = useState(false);

  useEffect(() => {
    if (chat && chat.internal_config) {
      setCanMA(chat.internal_config.canMA);
      setDebug(chat.internal_config.debug);
      setMaxMADepth(chat.internal_config.maxMADepth);
      setAlwaysInvokeAllByUser(chat.internal_config.alwaysInvokeAllByUser);
      setAlwaysInvokeAllByAgent(chat.internal_config.alwaysInvokeAllByAgent);
    }
  }, [chat]);

  const onClickSave = useCallback(async () => {
    setIsSaving(true);
    try {
      await dispatch(updateChat(chatId, {
        internal_config: {
          canMA,
          debug,
          maxMADepth,
          alwaysInvokeAllByUser,
          alwaysInvokeAllByAgent,
        }
      }));
      setIsOpened(false);
    }
    catch (err) {
      const errorMessage = getDisplayError(err);
      dispatch(addToast('Oops', 'Error saving config: ' + errorMessage, 'danger'));
    }
    setIsSaving(false);
  }, [dispatch, canMA, debug, maxMADepth, chatId, alwaysInvokeAllByUser, alwaysInvokeAllByAgent]);

  const buttons: ButtonItem[] = [{
    title: "Close",
    action: 'close',
    variant: 'default',
    outline: true,
  }, {
    title: "Save",
    variant: 'success',
    onClick: onClickSave,
    outline: true,
    isLoading: isSaving,
  }];

  const onDebugChanged = (e: any) => setDebug(e.target.value);
  const onCanMAChanged = (e: any) => setCanMA(e.target.value);
  const onMADepthChanged = (e: React.ChangeEvent<HTMLInputElement>) => setMaxMADepth(parseInt(e.target.value));
  const onAlwaysInvokeAllByUserChanged = (e: any) => setAlwaysInvokeAllByUser(e.target.value);
  const onAlwaysInvokeAllByAgentChanged = (e: any) => setAlwaysInvokeAllByAgent(e.target.value);

  return (
    <Modal buttons={buttons} title={`Superuser Settings ${chat!.title}`} isOpened={isOpened} size="mid" navigate={navigate}>
      {chat?.config &&
        <>
          <Checkbox value={debug} onChange={onDebugChanged} title="Enable Agent processing debug" className="mb-3" />
          <Checkbox value={canMA} onChange={onCanMAChanged} title="Enable Multi Agent chat" className="mb-3" />
          {canMA && <Input type="number" keyfilter={'pnum'} value={maxMADepth} onChange={onMADepthChanged} className="mb-3" label="Max Multi Agent depth" sublabel="How many times can agents talk to each other without human interruption. If the number is too high the conversation could keep always going." />}
          <Checkbox value={alwaysInvokeAllByUser} onChange={onAlwaysInvokeAllByUserChanged} title="Always Invoke All Agents on User Message" className="mb-3" sublabel="Whenever user sends a message to the chat, all agents are contacted regardless of them being tagged in the message or not." />
          {canMA && <Checkbox value={alwaysInvokeAllByAgent} onChange={onAlwaysInvokeAllByAgentChanged} title="Always Invoke All Agents on Agent Message" className="mb-3" sublabel="Whenever agent responds to a user message, all other agents are informed about the agent's response too. Only applies to Multi Agent chats." />}
        </>
      }
    </Modal >
  )
}

export default DebugModal;
