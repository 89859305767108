import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isEmailValid } from "@hubblai/hubbl-core/lib/validator.js";
import { Button, Input, Tag, Label, Modal, ButtonItem } from '@hubblai/hubbl-ui/components/index.js';

import { useChat } from "~/store/chats/hooks";
import { useAppDispatch } from "@hubblai/hubbl-ui/store/index.js";
import { inviteUsers } from "~/store/chats/actions";

const InviteUserModal: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const chatId = params.chatId!;
  const chat = useChat(chatId);
  const [emails, setEmails] = useState<string[]>([]);
  const [currentEmail, setCurrentEmail] = useState('');
  const [isCurrentEmailInvalid, setIsCurrentEmailInvalid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const buttons: ButtonItem[] = [{
    title: "Close",
    action: 'close',
    variant: 'default',
    outline: true,
  }];

  const onClickSendEmail = useCallback(async () => {
    if (isEmailValid(currentEmail)) {
      const emailIndex = emails.findIndex(e => e === currentEmail);
      if (emailIndex === -1) {
        setIsCurrentEmailInvalid(false);
        setIsSubmitting(true);
        await dispatch(inviteUsers(chatId, [currentEmail]));
        setIsSubmitting(false);
        const newEmails = [...emails];
        newEmails.push(currentEmail);
        setEmails(newEmails);
      }
      setCurrentEmail('');
    } else {
      setIsCurrentEmailInvalid(true);
    }
  }, [chatId, dispatch, setCurrentEmail, currentEmail, setEmails, emails]);

  const onCurrentEmailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentEmail(e.target.value);
  }

  return (
    <Modal buttons={buttons} title={`Invite users to ${chat!.title}`} navigate={navigate} isOpened>
      <div className="flex flex-1">
        <Input className="flex-1 mr-2" inputClassName={"w-full"} value={currentEmail} onChange={onCurrentEmailChanged} placeholder="Enter email address (i.e. john.doe@gmail.com)" error={isCurrentEmailInvalid ? 'Invalid email' : ''} />
        <Button title="Send Invite" variant="primary" onClick={onClickSendEmail} isLoading={isSubmitting} />
      </div>
      {emails.length > 0 &&
        <div className="mt-3">
          {emails.map(email =>
            <div className="flex flex-1 items-center mb-1 border-b px-1 py-1" key={email}>
              <Label title={email} className={'px-2 py-1 flex-1'} />
              <Tag title="Invite sent" variant="success" inverse className="px-3 py-1 font-nor" />
            </div>
          )}
        </div>
      }
    </Modal >
  )
}

export default InviteUserModal;
