import React, { useEffect, useRef, useState } from 'react';

import { Button, Spinner } from '@hubblai/hubbl-ui/components/index.js';

import ChatListItem from './components/ChatListItem';
import styles from './ChatsBar.module.css';
import { useAppDispatch } from '@hubblai/hubbl-ui/store/index.js';
import { createChat } from '~/store/chats/actions';
import { useChats, useIsCreating, useIsFetchingAll, useHasNextPage } from '~/store/chats/hooks';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Chat } from '~/store/models';
import { fetchChats } from '~/store/chats/actions';
import config from '~/config';
import { useCurrentUserId } from '@hubblai/hubbl-ui/store/auth/hooks.js';


const ChatsBar: React.FC = () => {
  const rootRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userId = useCurrentUserId();
  const isCreating = useIsCreating();
  const chats: Chat[] = useChats();

  const isFetching: boolean = useIsFetchingAll();
  const hasNextPage: boolean = useHasNextPage();
  const [hasScroll, setHasScroll] = useState(false);

  const onClickNew = async () => {
    const newChat = await dispatch(createChat());
    navigate(`/chats/${newChat.id}`);
  };

  const fetchMore = () => {
    if (!isFetching && hasNextPage) {
      dispatch(fetchChats());
    }
  }

  useEffect(() => {
    if (rootRef.current) {
      const hasScrollNow = rootRef.current.scrollHeight > rootRef.current.clientHeight;
      if (hasScroll !== hasScrollNow) {
        setHasScroll(hasScrollNow);
      }
    }
  }, [chats, hasScroll])

  const onRootScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target as HTMLDivElement;
    if (scrollHeight <= scrollTop + clientHeight + config.SCROLL_TRIGGER_FETCH_OFFSET) {
      fetchMore();
    }
  }

  return (
    <div className={styles.ChatsBar} onScroll={onRootScroll} ref={rootRef}>
      <div className="flex-1">
        {chats.map(chat => <ChatListItem chat={chat} key={chat.id} currentUserId={userId} />)}
        {isFetching && <Spinner />}
      </div>
      <div className={clsx('p-2 text-center', { [styles.floating]: hasScroll })}>
        <Button onClick={onClickNew} title="Create New Chat" icon='plus' isLoading={isCreating} size="sm" />
      </div>
    </div >
  );
}

export default ChatsBar;
