import React from 'react';
import { Outlet } from 'react-router-dom';

import ChatsBar from '~/components/ChatsBar';
import styles from './Chats.module.css';

const ChatsLayout: React.FC = () => {
  return (
    <>
      <div className={styles.Sidebar}>
        <ChatsBar />
      </div>
      <div className={styles.Content}>
        <Outlet />
      </div>
    </>
  );
}

export default ChatsLayout;
