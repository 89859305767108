import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import styles from "./MessageList.module.css";
import { useMessagesLength, useIsFetching, useHasNextPage, useHasMountFetched, useMessageGroups, MessageGroup as MessageGroupType, useStreamedMessages } from '~/store/messages/hooks';
import { useAppDispatch } from '@hubblai/hubbl-ui/store/index.js';
import { appendNewMessage, cancelMessage, fetchMessages } from '~/store/messages/actions';
import Message from '~/components/Chat/components/Message';
import config from '~/config';

import { markAsRead } from '~/store/chats/actions';
import { useCurrentUserId } from '@hubblai/hubbl-ui/store/auth/hooks.js';
import MessageGroupHeader from '../MessageGroupHeader';
import { Spinner } from '@hubblai/hubbl-ui/components/index.js';

type Props = {
  chatId: string,
  formHeight: number,
}

type MessageGroupProps = {
  group: MessageGroupType,
  onClickReply: (group: MessageGroupType) => void,
  onClickCancel: (group: MessageGroupType) => void,
  currentUserId: string,
}

const MessageGroup: React.FC<MessageGroupProps> = ({ group, onClickReply, onClickCancel, currentUserId }) => {
  return (
    <div className={clsx(styles.MessageGroup, {
      [styles.current]: group.isByCurrentUser,
      [styles.client]: group.name.length === 0,
    })}>
      {group.name.length > 0 && <MessageGroupHeader group={group} onClickCancel={onClickCancel} onClickReply={onClickReply} />}
      {group.items.map(message => (
        <Message key={message.id} message={message} currentUserId={currentUserId} />
      ))}
    </div>
  );
}

const BOTTOM_FORM_PADDING = 20;
const TOP_FORM_PADDING = 20;
const SCROLL_TOP_CLOSE_TO_BOTTOM_OFFSET = 5;

const MessageList: React.FC<Props> = ({ chatId, formHeight }) => {
  const messagesLength = useMessagesLength(chatId);
  const streamedMessages = useStreamedMessages(chatId);
  const currentUserId = useCurrentUserId();
  const groups = useMessageGroups(chatId, currentUserId);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const isFetching = useIsFetching(chatId);
  const hasNextPage = useHasNextPage(chatId);
  const dispatch = useAppDispatch();
  const rootRef = useRef<HTMLDivElement>(null);
  const [hasUserScrolled, setHasUserScrolled] = useState(false);
  const hasMountFetched = useHasMountFetched(chatId);
  const [scrollLock, setScrollLock] = useState<number | null>(null);

  const scrollToBottom = (instant: boolean = false) => {
    if (rootRef && rootRef.current) {
      messagesEndRef?.current?.scrollIntoView({ behavior: instant ? "instant" : "smooth" });
    }
  }

  const applyScrollLock = React.useCallback(() => {
    if (scrollLock && rootRef?.current) {
      rootRef.current.scrollTo({ top: rootRef.current.scrollHeight - scrollLock, behavior: "instant" });
      setScrollLock(null);
    }
  }, [scrollLock]);

  const fetchMoreMessages = React.useCallback(() => {
    if (!isFetching && chatId) {
      dispatch(fetchMessages(chatId));
    }
  }, [dispatch, isFetching, chatId]);

  useEffect(() => {
    if (!isFetching && scrollLock) {
      applyScrollLock();
    } else if (isFetching && !scrollLock && rootRef.current) {
      setScrollLock(rootRef.current.scrollHeight - rootRef.current.scrollTop);
    }
  }, [scrollLock, isFetching, applyScrollLock]);

  useEffect(() => {
    if (!hasMountFetched) {
      fetchMoreMessages();
    }
  }, [chatId, fetchMoreMessages, hasMountFetched]);

  useEffect(() => {
    if (messagesLength > 0 && !hasUserScrolled) {
      scrollToBottom(true);
    }
  }, [messagesLength, hasUserScrolled, streamedMessages])

  useEffect(() => {
    // TODO:P2 this is insufficient, as messages come in it will keep marking the chat as unread
    if (hasMountFetched) {
      dispatch(markAsRead(chatId));
    }
  }, [hasMountFetched, dispatch, chatId])

  useEffect(() => {
    if (formHeight > 0 && !hasUserScrolled) {
      scrollToBottom(false);
    }
  }, [formHeight, hasUserScrolled]);

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target as HTMLDivElement;
    const hasUserScrolledNow = scrollTop + clientHeight + SCROLL_TOP_CLOSE_TO_BOTTOM_OFFSET < scrollHeight;
    if (hasUserScrolledNow !== hasUserScrolled) {
      setHasUserScrolled(hasUserScrolledNow);
    }
    if (scrollTop < config.SCROLL_TRIGGER_FETCH_OFFSET && hasNextPage) {
      fetchMoreMessages();
    }
  }

  const onClickReply = (group: MessageGroupType) => {
    dispatch(appendNewMessage(chatId, group.tag + ' '));
  }

  const onClickCancel = (group: MessageGroupType) => {
    const lastMessage = group.items[group.items.length - 1];
    if (lastMessage) {
      dispatch(cancelMessage(chatId, lastMessage.id));
    }
  }

  return (
    <div ref={rootRef} className={clsx(styles.MessageList)} style={{ 'paddingBottom': BOTTOM_FORM_PADDING + TOP_FORM_PADDING + formHeight }} onScroll={onScroll}>
      {isFetching && <Spinner />}
      {groups && groups.map(group => <MessageGroup key={group.id} currentUserId={currentUserId} group={group} onClickReply={onClickReply} onClickCancel={onClickCancel} />)}
      <div ref={messagesEndRef} />
    </div >
  );
}

export default MessageList;
