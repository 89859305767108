import React from 'react';
import clsx from 'clsx';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Icon, Avatar, BreadCrumb } from '@hubblai/hubbl-ui/components/index.js';

import styles from './Users.module.css';
import { MenuItem } from 'primereact/menuitem';
import { useUser } from '@hubblai/hubbl-ui/store/auth/hooks.js';

type SidebarMenuItemProps = {
  icon: string,
  title: string,
  to: string,
}

const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({ icon, title, to }) => {
  const { pathname } = useLocation();
  return (
    <Link className={clsx(styles.SidebarMenuItem, {[styles.active]: pathname === to }) } to={to}>
      <Icon name={icon} className='mr-2' />
      <span>{title}</span>
    </Link>
  );
}

const DEFAULT_PATHNAME = '/users/me';
const pathNameToLabel: { [pathname: string]: string } = {
  [DEFAULT_PATHNAME]: 'Profile',
  '/users/me/invitations': 'Invitations',
}

const UsersLayout: React.FC = () => {
  const user = useUser();
  const userId = 'me';
  const navigate = useNavigate();

  const home: MenuItem = { command: () => navigate('/'), icon: 'pi pi-home' };
  const breadcrumbs: MenuItem[] = [
    { command: () => navigate('/users/me'), label: pathNameToLabel['/users/me'] },
  ];

  const { pathname } = useLocation();
  if (pathname !== DEFAULT_PATHNAME) {
    breadcrumbs.push({ label: pathNameToLabel[pathname] || 'This' });
  }

  return (
    <div className={styles.Root}>
      <div className={styles.PageContent}>
        <div className={styles.Sidebar}>
          <div className="flex flex-col text-center items-center">
            <Avatar image={user?.getAvatar()} className={styles.Avatar} />
            <div className={styles.Title}>{user?.getDisplayName()}</div>
          </div>
          <div className={styles.SidebarMenu}>
            <SidebarMenuItem icon="profile" title="Profile" to={`/users/${userId}`} />
            <SidebarMenuItem icon="users" title="Invitations" to={`/users/${userId}/invitations`} />
          </div>
        </div>
        <div className={styles.Content}>
          <BreadCrumb className={styles.Breadcrumbs} home={home} model={breadcrumbs} />
          <div className={styles.Outlet}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UsersLayout;
